import React, { useState } from "react";

const PostContent = ({ content }) => {
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  return (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: showFullContent ? content : content.substring(0, 100) + "...",
        }}
      />
      <button
        onClick={toggleContent}
        className="text-blue-500 hover:text-blue-700 transition duration-300 mt-2"
      >
        {showFullContent ? "Show Less" : "Show More"}
      </button>
    </div>
  );
};

export default PostContent;
