import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import apiService from "../../ApiService";
import { baseUrl } from "../../config";
const ContactUsSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    recipient: "support@geniusnexa.com",
  });
  const [statusMessage, setStatusMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const result = await apiService.sendEmail({
        name: formData.name,
        email: formData.email,
        message: formData.message,
        recipient: formData.recipient,
      });

      setStatusMessage(
        result.status === "success"
          ? `Thank you, ${formData.name}, for reaching out. We will get back to you within 2 hours.`
          : "An error occurred while sending the message. Please try again."
      );

      if (result.status === "success") {
        // Reset form fields
        setFormData({
          name: "",
          email: "",
          message: "",
          recipient: "support@geniusnexa.com",
        });
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setStatusMessage(
        "An error occurred while sending the message. Please try again."
      );
    }
  };

  return (
    <div className="bg-blue-100 py-12" id="contact-us">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Contact Us</h2>
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <form
              onSubmit={handleSubmit}
              className="bg-white p-8 rounded-lg shadow-lg"
            >
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Name:
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  autoComplete="name"
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  autoComplete="email"
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="message"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Message:
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="5"
                  required
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-300"
              >
                Send Message
              </button>
              <p
                className={`mt-4 text-center ${
                  statusMessage.includes("Thank")
                    ? "text-green-500"
                    : "text-red-500"
                }`}
              >
                {statusMessage}
              </p>
            </form>
          </div>
          <div className="w-full md:w-1/2 flex flex-col items-center">
            <div className="mb-8">
              <h3 className="text-2xl font-semibold mb-4">Follow Us</h3>
              <div className="flex space-x-4">
                <a
                  href="https://www.facebook.com/yourprofile"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    size="2x"
                    className="text-blue-600 hover:text-blue-800 transition duration-300"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/103303143/admin/feed/posts/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    size="2x"
                    className="text-blue-700 hover:text-blue-900 transition duration-300"
                  />
                </a>
                <a
                  href="https://www.instagram.com/yourprofile"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="2x"
                    className="text-pink-600 hover:text-pink-800 transition duration-300"
                  />
                </a>
              </div>
            </div>
            <div className="w-full">
              <h3 className="text-2xl font-semibold text-center mb-4">
                Our Location
              </h3>
              <div className="flex justify-center">
                <img
                  src={`${baseUrl}/images/geniusnexa.png`}
                  alt="GeniusNexa office location on Google Map"
                  className="w-full md:w-2/3 lg:w-1/2 object-cover rounded-lg shadow-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsSection;
