import React, { useState } from "react";
import { formatDateTime } from "../../utils/DateUtils";
import { baseUrl } from "../../config";

const PostView = ({ post, onBack }) => {
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  return (
    <div className="container mx-auto py-12 px-4">
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">{post.Title}</h2>
          <button
            onClick={onBack}
            className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition duration-300"
          >
            Back
          </button>
        </div>
        <div>
          {post && (
            <div>
              <div className="mb-4">
                <img
                  src={baseUrl + post.ImageUrl}
                  alt={post.Title}
                  className="w-full h-auto rounded-lg"
                />
              </div>
              <div className="flex items-center mb-4">
                <p className="font-semibold">{post.Author}</p>
                <p className="ml-4 text-gray-500">
                  {formatDateTime(post.ModifiedDate)}
                </p>
              </div>
              <div className="prose">
                <div
                  dangerouslySetInnerHTML={{
                    __html: showFullContent
                      ? post.Content
                      : post.Content.substring(0, 200) + "...",
                  }}
                />
                <button
                  onClick={toggleContent}
                  className="text-blue-500 hover:text-blue-700 transition duration-300 mt-2"
                >
                  {showFullContent ? "Show Less" : "Show More"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PostView;
