import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import UserContext from "../../contexts/UserContext";
import apiService from "../../ApiService"; // Adjust the import path as necessary
import GoogleLoginButton from "../../components/GoogleLoginButton";

const Login = () => {
  const [useremail, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { login, isAuthenticated } = useContext(UserContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const result = await apiService.login(useremail, password, "login"); // Call the login method from apiService

      if (result.success) {
        login(result.user);
        const { role } = result.user;
        if (role === "admin") {
          history.push("/admin");
        } else {
          history.push("/");
        }
        setErrorMessage("");
      } else {
        setErrorMessage("Invalid credentials");
      }
    } catch (error) {
      setErrorMessage("Error logging in. Please try again.");
      console.error("Error logging in:", error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/");
    } else {
      setLoading(false);
    }
  }, [isAuthenticated, history]);

  if (loading) {
    return <div className="loading-message">Checking login status...</div>;
  }

  return (
    <div className="container mx-auto py-12 px-4">
      <div className="max-w-md mx-auto bg-white p-8 rounded-lg shadow-lg">
        {errorMessage && (
          <div className="mb-4 text-red-500 text-center">{errorMessage}</div>
        )}
        <h2 className="text-2xl font-bold text-center mb-6">Login</h2>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Email:</label>
            <input
              name="useremail"
              type="email"
              value={useremail}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Password:</label>
            <input
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-300"
          >
            Login
          </button>
        </form>
        <hr className="my-6" />
        <div className="social-login">
          <GoogleLoginButton />
        </div>
        <hr className="my-6" />
        <p className="text-center">
          If you are a new user, please register below.
        </p>
        <button
          onClick={() => history.push("/register")}
          className="w-full bg-green-500 text-white py-2 rounded-lg hover:bg-green-600 transition duration-300 mt-4"
        >
          Register
        </button>
      </div>
    </div>
  );
};

export default Login;
