import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faClipboardCheck,
  faTools,
  faLifeRing,
} from "@fortawesome/free-solid-svg-icons";
import { baseUrl } from "../../config";

const OperationModel = () => {
  return (
    <div className="bg-gray-100 py-12" id="operation-model">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">
          Our Operation Model
        </h2>
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <div className="text-center mb-8 md:mb-0 md:text-left">
              <p>
                At GeniusNexa, we follow a client-centric operation model to
                deliver solutions tailored to our clients' unique needs. Our
                process involves the following key steps:
              </p>
            </div>
            <ul className="space-y-8">
              <li className="flex items-start space-x-4">
                <FontAwesomeIcon
                  icon={faComment}
                  className="text-blue-500 text-3xl"
                />
                <div>
                  <h3 className="text-xl font-semibold mb-2">Consultation</h3>
                  <p className="text-gray-700">
                    Understanding client requirements and goals through detailed
                    discussions and analysis.
                  </p>
                </div>
              </li>
              <li className="flex items-start space-x-4">
                <FontAwesomeIcon
                  icon={faClipboardCheck}
                  className="text-blue-500 text-3xl"
                />
                <div>
                  <h3 className="text-xl font-semibold mb-2">Planning</h3>
                  <p className="text-gray-700">
                    Developing a strategic plan that outlines the steps and
                    technologies needed to achieve the desired outcomes.
                  </p>
                </div>
              </li>
              <li className="flex items-start space-x-4">
                <FontAwesomeIcon
                  icon={faTools}
                  className="text-blue-500 text-3xl"
                />
                <div>
                  <h3 className="text-xl font-semibold mb-2">Execution</h3>
                  <p className="text-gray-700">
                    Implementing the plan using cutting-edge technologies and
                    best practices.
                  </p>
                </div>
              </li>
              <li className="flex items-start space-x-4">
                <FontAwesomeIcon
                  icon={faLifeRing}
                  className="text-blue-500 text-3xl"
                />
                <div>
                  <h3 className="text-xl font-semibold mb-2">Support</h3>
                  <p className="text-gray-700">
                    Providing ongoing support and maintenance to ensure the
                    continued success of the solution.
                  </p>
                </div>
              </li>
            </ul>
            <div className="mt-8">
              <p className="text-gray-700">
                At GeniusNexa, we are committed to delivering exceptional
                service tailored to your specific business requirements. Contact
                us today to learn how we can empower your business with
                innovative solutions and unparalleled support.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center">
            <img
              src={`${baseUrl}/images/OperationModel.png`}
              alt="Operation Model"
              className="mt-8 md:mt-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperationModel;
