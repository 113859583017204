import React from "react";
import { baseUrl } from "../../config";

const clients = [
  { name: "Client 1", logo: `${baseUrl}/images/client1.png` },
  // Add more clients as needed
];

const Clients = () => {
  return (
    <div className="bg-white py-12" id="clients">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Our Clients</h2>
        <div className="flex flex-wrap justify-center items-center -mx-4">
          {clients.map((client, index) => (
            <div key={index} className="w-full sm:w-1/2 md:w-1/4 px-4 mb-8">
              <div className="bg-gray-100 p-4 rounded-lg shadow-lg flex justify-center items-center">
                <img
                  src={client.logo}
                  alt={client.name}
                  className="w-full h-auto object-contain"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Clients;
