import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
import { baseUrl } from "../../config";
import apiService from "../../ApiService";
import { trackEvent } from "../../components/trackingUtils";
const dummyImageUrl = "https://via.placeholder.com/400x200?text=Dummy+Image";

const BlogPost = ({ SEOUrl, ...props }) => {
  const [filteredPost, setFilteredPost] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalPages, setTotalPages] = useState(1); // State to store total pages

  useEffect(() => {
    trackEvent("PageLoad", "", "BlogPost");
    const fetchFilteredPosts = async () => {
      setLoading(true);
      try {
        const data = await apiService.fetchBlogPostByCategory(SEOUrl, page);
        setTotalPages(data.totalPages); // Update total pages from API response
        if (data.posts && data.posts.length === 0) {
          setHasMore(false); // No more items, stop pagination
          if (page === 1) {
            setError("No items found."); // Show error if no items found on the first page
          }
        } else {
          if (page === 1) {
            setFilteredPost(data.posts);
          } else {
            setFilteredPost((prevPosts) => [...prevPosts, ...data.posts]);
          }
          setError(null); // Clear any existing error
        }
      } catch (error) {
        console.error("Error fetching filtered blog posts:", error);
        if (error.response && error.response.status === 404) {
          setHasMore(false); // No more items, stop pagination
          if (page === 1) {
            setError("No items found."); // Show error if no items found on the first page
          }
        } else {
          setError(`${error.message}`); // Set generic error message for other errors
        }
      } finally {
        setLoading(false);
      }
    };

    fetchFilteredPosts();
  }, [SEOUrl, page]);

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const convertToTitleCase = (text) => {
    return text
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2") // Add space between uppercase letters and lowercase letters
      .replace(/-/g, " ") // Replace hyphens with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  // Extracting keywords and description from the first post if available
  const firstPost = filteredPost.length > 0 ? filteredPost[0] : null;
  const seoKeywords = firstPost
    ? firstPost.keywords
    : "GeniusNexa blog, Nexa tech articles, Nexa technology trends";
  const seoDescription = firstPost
    ? firstPost.description
    : "Discover tech insights, tutorials, reviews, and industry updates on the GeniusNexa blog.";

  return (
    <div className="container mx-auto py-12 px-4">
      <SEO
        title={"GeniusNexa Blog: " + convertToTitleCase(SEOUrl)}
        description={seoDescription}
        keywords={seoKeywords}
        image={baseUrl + "/blogs/" + SEOUrl}
        url={baseUrl + "/blogs/" + SEOUrl}
      />
      <h1 className="text-3xl font-bold mb-8">{convertToTitleCase(SEOUrl)}</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
        {filteredPost &&
          filteredPost.map((post, idx) => (
            <Link
              key={idx}
              to={`/blogs/${SEOUrl}/${post.SEOUrl}`}
              className=" block bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
            >
              <img
                src={
                  post.ImageUrl ? baseUrl + "/" + post.ImageUrl : dummyImageUrl
                }
                alt={post.Title}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-lg font-semibold mb-2">{post.Title}</h3>
              </div>
            </Link>
          ))}
      </div>
      {loading && (
        <div className="text-center mt-8">
          <div
            className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {error && (
        <div className="alert alert-danger text-center mt-8" role="alert">
          {error}
        </div>
      )}
      {page < totalPages && hasMore && !loading && !error && (
        <div className="text-center mt-8">
          <button
            className="bg-blue-500 text-white py-2 px-6 rounded-lg hover:bg-blue-600 transition duration-300"
            onClick={handleLoadMore}
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default BlogPost;
