import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faFileAlt,
  faUsers,
  faCog,
  faChartBar,
  faTools,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import UserContext from "../../contexts/UserContext";
import AdminDashboard from "./AdminDashboard";
import AdminPosts from "./Posts";
import ReviewBlog from "./ReviewBlogs";
import Users from "./Users";
import AdminSettings from "./AdminSettings";
import AdminReports from "./AdminReports";
import AdminTools from "./AdminTools";
import SubItem1 from "./SubItem1";
import SubItem2 from "./SubItem2";

const AdminLayout = () => {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const [open, setOpen] = useState({});
  const [activeComponent, setActiveComponent] = useState(null);

  // Function to determine if the user is an admin
  const isAdminUser = () => {
    return user && user.role === "admin";
  };

  // Effect to set active component based on the current path
  useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case "/admin/dashboard":
        setActiveComponent("dashboard");
        break;
      case "/admin/posts":
        setActiveComponent("posts");
        break;
      case "/admin/reviewblog":
        setActiveComponent("reviewblog");
        break;
      case "/admin/users":
        setActiveComponent("users");
        break;
      case "/admin/settings":
        setActiveComponent("settings");
        break;
      case "/admin/reports":
        setActiveComponent("reports");
        break;
      case "/admin/tools":
        setActiveComponent("tools");
        break;
      case "/admin/subitem1":
        setActiveComponent("subitem1");
        break;
      case "/admin/subitem2":
        setActiveComponent("subitem2");
        break;
      default:
        setActiveComponent(null);
    }
  }, [location.pathname]);

  // Function to render the active component based on activeComponent state
  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "dashboard":
        return <AdminDashboard />;
      case "posts":
        return <AdminPosts user={user} />;
      case "reviewblog":
        return <ReviewBlog user={user} />;
      case "users":
        return <Users />;
      case "settings":
        return <AdminSettings />;
      case "reports":
        return <AdminReports />;
      case "tools":
        return <AdminTools />;
      case "subitem1":
        return <SubItem1 />;
      case "subitem2":
        return <SubItem2 />;
      default:
        return <div>Select an option from the menu</div>;
    }
  };

  // Handle toggle for submenus
  const handleToggle = (menu) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [menu]: !prevOpen[menu],
    }));
  };

  return (
    <div className="flex min-h-screen">
      <aside className="w-64 bg-gray-800 text-white flex flex-col">
        <nav className="flex-1 p-4">
          {isAdminUser() ? (
            <ul className="space-y-2">
              <li
                className={
                  activeComponent === "dashboard"
                    ? "bg-gray-700 rounded-lg"
                    : ""
                }
              >
                <Link
                  to="/admin/dashboard"
                  onClick={() => setActiveComponent("dashboard")}
                  className="flex items-center p-2 space-x-2 hover:bg-gray-700 rounded-lg"
                >
                  <FontAwesomeIcon icon={faTachometerAlt} />
                  <span>Dashboard</span>
                </Link>
              </li>
              <li
                className={
                  activeComponent === "posts" ? "bg-gray-700 rounded-lg" : ""
                }
              >
                <Link
                  to="/admin/posts"
                  onClick={() => setActiveComponent("posts")}
                  className="flex items-center p-2 space-x-2 hover:bg-gray-700 rounded-lg"
                >
                  <FontAwesomeIcon icon={faFileAlt} />
                  <span>Posts</span>
                </Link>
              </li>
              {user.role === "admin" && (
                <li
                  className={
                    activeComponent === "reviewblog"
                      ? "bg-gray-700 rounded-lg"
                      : ""
                  }
                >
                  <Link
                    to="/admin/reviewblog"
                    onClick={() => setActiveComponent("reviewblog")}
                    className="flex items-center p-2 space-x-2 hover:bg-gray-700 rounded-lg"
                  >
                    <FontAwesomeIcon icon={faCheck} />
                    <span>Review Blog</span>
                  </Link>
                </li>
              )}
              <li
                className={
                  activeComponent === "users" ? "bg-gray-700 rounded-lg" : ""
                }
              >
                <Link
                  to="/admin/users"
                  onClick={() => setActiveComponent("users")}
                  className="flex items-center p-2 space-x-2 hover:bg-gray-700 rounded-lg"
                >
                  <FontAwesomeIcon icon={faUsers} />
                  <span>Users</span>
                </Link>
              </li>
              <li
                className={
                  activeComponent === "settings" ? "bg-gray-700 rounded-lg" : ""
                }
              >
                <Link
                  to="/admin/settings"
                  onClick={() => setActiveComponent("settings")}
                  className="flex items-center p-2 space-x-2 hover:bg-gray-700 rounded-lg"
                >
                  <FontAwesomeIcon icon={faCog} />
                  <span>Settings</span>
                </Link>
              </li>
              <li
                className={
                  activeComponent === "reports" ? "bg-gray-700 rounded-lg" : ""
                }
              >
                <Link
                  to="/admin/reports"
                  onClick={() => setActiveComponent("reports")}
                  className="flex items-center p-2 space-x-2 hover:bg-gray-700 rounded-lg"
                >
                  <FontAwesomeIcon icon={faChartBar} />
                  <span>Reports</span>
                </Link>
              </li>
              <li
                className={
                  activeComponent === "tools" ? "bg-gray-700 rounded-lg" : ""
                }
              >
                <Link
                  to="/admin/tools"
                  onClick={() => setActiveComponent("tools")}
                  className="flex items-center p-2 space-x-2 hover:bg-gray-700 rounded-lg"
                >
                  <FontAwesomeIcon icon={faTools} />
                  <span>Tools</span>
                </Link>
              </li>
              {/* Add multilevel menu items here */}
              <li className={open["nested1"] ? "bg-gray-700 rounded-lg" : ""}>
                <a
                  href="#!"
                  onClick={() => handleToggle("nested1")}
                  className="flex items-center p-2 space-x-2 hover:bg-gray-700 rounded-lg"
                >
                  <FontAwesomeIcon icon={faTools} />
                  <span>Nested Menu</span>
                </a>
                <Collapse in={open["nested1"]}>
                  <ul className="pl-6 space-y-2">
                    <li
                      className={
                        activeComponent === "subitem1"
                          ? "bg-gray-700 rounded-lg"
                          : ""
                      }
                    >
                      <Link
                        to="/admin/subitem1"
                        onClick={() => setActiveComponent("subitem1")}
                        className="flex items-center p-2 space-x-2 hover:bg-gray-700 rounded-lg"
                      >
                        <span>Subitem 1</span>
                      </Link>
                    </li>
                    <li
                      className={
                        activeComponent === "subitem2"
                          ? "bg-gray-700 rounded-lg"
                          : ""
                      }
                    >
                      <Link
                        to="/admin/subitem2"
                        onClick={() => setActiveComponent("subitem2")}
                        className="flex items-center p-2 space-x-2 hover:bg-gray-700 rounded-lg"
                      >
                        <span>Subitem 2</span>
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </li>
            </ul>
          ) : (
            <p>Access denied. Admin only.</p>
          )}
        </nav>
        <div className="p-4 text-center">
          <p>Admin Footer</p>
        </div>
      </aside>
      <main className="flex-1 p-4">{renderActiveComponent()}</main>
    </div>
  );
};

export default AdminLayout;
