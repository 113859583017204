import React, { createContext, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import apiService from "../ApiService"; // Import your common API service

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["sessionId"]);

  useEffect(() => {
    const checkLoggedIn = async () => {
      const sessionId = cookies.sessionId;
      if (!sessionId) return;

      try {
        const response = await apiService.checkSession(sessionId);

        if (response.loggedIn) {
          setUser(response.user);
          setIsAuthenticated(true);
        } else {
          setUser(null);
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Error checking login status:", error);
      }
    };

    checkLoggedIn();
  }, [cookies.sessionId]);

  const login = (user) => {
    setUser(user);
    setIsAuthenticated(true);
    const now = new Date();
    const expires = new Date(now.setDate(now.getDate() + 50)); // 50 days from now
    setCookie("sessionId", user.sessionId, {
      path: "/",
      expires,
      secure: true,
      sameSite: "Strict",
    });
  };

  const logout = async () => {
    try {
      const sessionId = cookies.sessionId;
      await apiService.logout(sessionId);
      setUser(null);
      setIsAuthenticated(false);
      removeCookie("sessionId", { path: "/" });
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <UserContext.Provider value={{ user, isAuthenticated, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
