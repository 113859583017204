import React from "react";
import { baseUrl } from "../../config";

const CEOQuotes = ({ photoUrl, quote, author }) => {
  return (
    <div className="bg-gray-100 py-12" id="ceo-quotes">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">CEO Message</h2>
        <div className="flex flex-col md:flex-row items-center md:items-start space-y-8 md:space-y-0 md:space-x-8">
          <div className="w-full md:w-1/3 flex justify-center">
            <img
              src={`${baseUrl}/images/CEO.png`}
              alt="CEO"
              className="rounded-full w-48 h-48 object-cover"
            />
          </div>
          <div className="w-full md:w-2/3">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <p className="text-lg text-gray-700 italic mb-4">
                &quot;Success is built on{" "}
                <strong>1% vision and 99% hard work</strong>. Stay focused, stay
                driven, and let's turn our ideas into achievements.&quot;
              </p>
              <p className="text-right text-gray-700 font-semibold">
                - Renu, CEO of GeniusNexa
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CEOQuotes;
