import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ServicesSection from "./Services";
import OperationModelSection from "./OperationModel";
import WhyChooseUsSection from "./WhyChooseUs";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import CEOQuotes from "./CEOQuotes";
import { baseUrl } from "../../config";
import { trackEvent } from "../../components/trackingUtils";
import Clients from "./Clients";

const Homepage = ({ services, blogs }) => {
  const quotes = [
    { text: "Quote 1 text...", author: "Author 1" },
    { text: "Quote 2 text...", author: "Author 2" },
    { text: "Quote 3 text...", author: "Author 3" },
    // Add more quotes as needed
  ];

  useEffect(() => {
    if (window.location.pathname === "/") {
      trackEvent("PageLoad", "", "Homepage");
    }
  }, []);

  const title = "GeniusNexa - Digital Services, Consulting, and Innovation Hub";
  const description =
    "GeniusNexa is a pioneering force in digital services and consulting, driving innovation worldwide.";
  const keywords =
    "GeniusNexa, Futuristic technologies, Cutting-edge advancements, Artificial intelligence (AI), Blockchain solutions, Technological revolution, Innovation, Automation, Robotics, Internet of Things (IoT), Software solutions, Digital transformation, Research and development, Client-centric solutions, Customized software, Cloud infrastructure, Agile methodology, Digital solutions, Business growth, Tailored solutions, Project management, Consulting services, Strategic planning, Application development, Continuous support, Technology pioneers, Industry leaders.";

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={`${baseUrl}/favicon.png`} />
        <meta property="og:url" content={baseUrl} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className="hero-section bg-gradient-to-r from-blue-900 to-blue-600 text-white h-screen flex items-center justify-center relative pt-24 md:pt-16 pb-24 md:pb-0">
        <div className="relative text-center px-4 max-w-4xl mx-auto">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">
            Transforming Ideas Into Innovation
          </h1>
          <p className="text-lg md:text-xl mb-8">
            At GeniusNexa, we're dedicated to transforming your digital
            aspirations into tangible realities.
          </p>
          <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4 mb-8">
            <div className="bg-white text-blue-900 p-4 rounded-lg shadow-lg w-full md:w-auto">
              <h3 className="text-2xl font-semibold mb-2">
                Innovative Solutions
              </h3>
              <p>
                We provide cutting-edge solutions to drive your business
                forward.
              </p>
            </div>
            <div className="bg-white text-blue-900 p-4 rounded-lg shadow-lg w-full md:w-auto">
              <h3 className="text-2xl font-semibold mb-2">Expert Team</h3>
              <p>
                Our team of experts is dedicated to delivering exceptional
                results.
              </p>
            </div>
            <div className="bg-white text-blue-900 p-4 rounded-lg shadow-lg w-full md:w-auto">
              <h3 className="text-2xl font-semibold mb-2">Customer Focused</h3>
              <p>We prioritize our clients' needs to ensure their success.</p>
            </div>
          </div>
          <a
            href="#contact-us"
            className="bg-blue-500 text-white py-3 px-6 rounded-lg hover:bg-blue-600 transition duration-300"
          >
            Get in Touch
          </a>
        </div>
      </div>
      <div className="mt-24 md:mt-0">
        <ServicesSection services={services} />
      </div>
      <OperationModelSection />
      <WhyChooseUsSection />
      <CEOQuotes quotes={quotes} />
      <Clients />
      <AboutUs />
      <ContactUs />
    </div>
  );
};

export default Homepage;
