import React, { useEffect } from "react";

const Dashboard = () => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const result = await apiService.getDashboardData();
        // setData(result.data);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container mx-auto py-12 px-4">
      <h1 className="text-3xl font-bold text-center mb-8">Admin Dashboard</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8"></div>
    </div>
  );
};

export default Dashboard;
