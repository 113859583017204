import React from "react";
import { baseUrl } from "../../config";

const WhyChooseUs = () => {
  return (
    <div className="bg-white py-12" id="why-choose-us">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Why Choose Us?</h2>
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-1/2 flex justify-center mb-8 md:mb-0">
            <img
              src={`${baseUrl}/images/whychooseusicon.png`}
              alt="Why Choose Us"
              className="mt-8 md:mt-0 md:mr-8"
            />
          </div>
          <div className="w-full md:w-1/2">
            <div className="mb-8">
              <p>
                At GeniusNexa, we excel in delivering innovative and customized
                software solutions. Our experienced team ensures we meet diverse
                client needs effectively.
              </p>
              <p>
                Whether enhancing cloud infrastructure, developing robust
                applications, or streamlining data processes, we leverage the
                latest technologies to exceed expectations.
              </p>
            </div>
            <h3 className="text-2xl font-semibold mb-4">
              Our Core Advantages:
            </h3>
            <ul className="list-disc list-inside space-y-4 text-left">
              <li>
                <strong>Customized Solutions:</strong> Tailored software
                solutions designed to fit your specific business needs.
              </li>
              <li>
                <strong>Expertise Across Domains:</strong> Extensive experience
                in various industries ensures comprehensive understanding of
                your challenges.
              </li>
              <li>
                <strong>Technology Pioneers:</strong> Leveraging cutting-edge
                technologies to deliver innovative and efficient solutions.
              </li>
              <li>
                <strong>Client-Centric Approach:</strong> We prioritize your
                success by closely aligning with your goals and objectives.
              </li>
              <li>
                <strong>Commitment to Quality:</strong> Ensuring excellence in
                every project through rigorous quality assurance processes.
              </li>
              <li>
                <strong>Agile Methodology:</strong> Rapid deployment and
                iterative development to adapt to evolving business needs.
              </li>
            </ul>
            <div className="mt-8">
              <p className="text-gray-700">
                At GeniusNexa, we empower businesses with transformative digital
                solutions. Contact us today to accelerate your growth and
                success in the digital age.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
