import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faTimes,
  faUpload,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "./PostForm.css"; // Ensure this is imported
import apiService from "../../ApiService";

const PostForm = ({
  post,
  onSave,
  onCancel,
  BlogID,
  UserID,
  Status,
  blogsData,
}) => {
  const [selectedBlogId, setSelectedBlog] = useState(null);
  const [selectedBlogTitle, setSelectedBlogTitle] = useState("Select");
  const [formData, setFormData] = useState({
    Title: post?.Title || "",
    SEOUrl: post?.SEOUrl ? post?.SEOUrl.replace(/\s+/g, "-") : "",
    MetaDescription: post?.MetaDescription || "",
    MetaKeywords: post?.MetaKeywords || "",
    Content: post?.Content || "",
    ImageUrl: post?.ImageUrl || null,
    TwitterImageUrl: post?.TwitterImageUrl || null,
    FacebookImageUrl: post?.FacebookImageUrl || null,
    WhatsappImageUrl: post?.WhatsappImageUrl || null,
    LinkedinImageUrl: post?.LinkedinImageUrl || null,
    TeamsImageUrl: post?.TeamsImageUrl || null,
    WindowsIcon: post?.WindowsIcon || null,
    PhotoCredit: post?.PhotoCredit || null,
    AppleTouchIcon: post?.AppleTouchIcon || null,
    AndroidIcon: post?.AndroidIcon || null,
    BlogID: BlogID,
    UserID: UserID,
    Status: 0,
  });
  const [showHtmlModal, setShowHtmlModal] = useState(false);
  const [htmlContent, setHtmlContent] = useState(formData.Content);

  useEffect(() => {
    function initalization() {
      console.log("blog id" + BlogID);
      const selectedBlog = blogsData?.find((blog) => blog.BlogID === BlogID);
      setSelectedBlog(BlogID);
      setSelectedBlogTitle(selectedBlog ? selectedBlog.Title : "Select");
    }
    initalization();
  }, [BlogID, blogsData]);

  const handleChange = (value) => {
    setFormData({ ...formData, Content: value || "" });
  };

  const handleSelectBlog = async (blogId, event) => {
    blogId = parseInt(blogId); // Convert blogId to an integer
    setSelectedBlog(blogId);
    const selectedBlog = blogsData?.find((blog) => blog.BlogID === blogId);
    setSelectedBlogTitle(selectedBlog ? selectedBlog.Title : "Filter Items");
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("image", file);

        // Assuming `uploadImage` is a function that makes the API request
        const response = await uploadImage(formData);

        // Check for errors in the response
        if (response.error) {
          throw new Error(response.error); // Throw error to be caught by catch block
        }
        // Convert arrays to comma-separated values
        const windowsIcons = response.socialMediaPaths["WindowsIcon"]
          ? response.socialMediaPaths["WindowsIcon"].join(",")
          : "";
        const appleTouchIcons = response.socialMediaPaths["AppleTouchIcon"]
          ? response.socialMediaPaths["AppleTouchIcon"].join(",")
          : "";
        const AndroidIcons = response.socialMediaPaths["AndroidIcon"]
          ? response.socialMediaPaths["AndroidIcon"].join(",")
          : "";

        // Update form data with the response data
        setFormData((prevFormData) => ({
          ...prevFormData,
          ImageUrl: response.filePath || prevFormData.ImageUrl,
          TwitterImageUrl:
            response.socialMediaPaths["twitter"] ||
            prevFormData.TwitterImageUrl,
          FacebookImageUrl:
            response.socialMediaPaths["facebook"] ||
            prevFormData.FacebookImageUrl,
          WhatsappImageUrl:
            response.socialMediaPaths["whatsapp"] ||
            prevFormData.WhatsappImageUrl,
          LinkedinImageUrl:
            response.socialMediaPaths["linkedin"] ||
            prevFormData.LinkedinImageUrl,
          TeamsImageUrl:
            response.socialMediaPaths["teams"] || prevFormData.TeamsImageUrl,
          WindowsIcon: windowsIcons,
          AppleTouchIcon: appleTouchIcons,
          AndroidIcon: AndroidIcons,
        }));

        console.log("Image uploaded successfully!");
      } catch (error) {
        console.error("Error uploading image:", error.message);

        // Optionally, you can show an error message to the user
        alert("There was an error uploading the image: " + error.message);
      }
    } else {
      alert("No file selected. Please select an image file to upload.");
    }
  };

  const handleShowHtmlModal = () => {
    setHtmlContent(formData.Content); // Set the modal content to current editor content
    setShowHtmlModal(true);
  };

  const handleHtmlContentChange = (e) => {
    setHtmlContent(e.target.value);
  };

  const handleSaveHtmlContent = () => {
    setFormData({ ...formData, Content: htmlContent });
    setShowHtmlModal(false);
  };

  const uploadImage = async (formData) => {
    try {
      const response = await apiService.uploadImage(formData);
      return response; // Assuming API returns image path
    } catch (error) {
      console.error("Error uploading image:", error.message);
      throw error;
    }
  };

  const handleRemoveImage = async () => {
    try {
      if (formData.ImageUrl) {
        const imageFileName = formData.ImageUrl.split("/").pop();
        // Remove image from server
        await apiService.removeImage(imageFileName);

        // Update local state to remove image URL
        setFormData((prevFormData) => ({
          ...prevFormData,
          ImageUrl: null,
          TwitterImageUrl: null,
          FacebookImageUrl: null,
          WhatsappImageUrl: null,
          LinkedinImageUrl: null,
          TeamsImageUrl: null,
          WindowsIcon: null,
          AppleTouchIcon: null,
          AndroidIcons: null,
        }));
      }
    } catch (error) {
      console.error("Error removing image:", error.message);
    }
  };

  const handleSubmit = async (e, approved) => {
    debugger;
    e.preventDefault();
    try {
      if (
        !formData.Title ||
        !formData.SEOUrl ||
        !formData.MetaDescription ||
        !formData.MetaKeywords ||
        !formData.Content
      ) {
        // Highlight fields and prevent submission if any required field is empty
        alert("Please fill in all fields.");
        return;
      }

      if (post.PostID != null) {
        console.log(Status);
        const updatedPost = {
          ...formData,
          PostID: post.PostID,
          UserID: UserID,
          BlogID: selectedBlogId,
          LikeCount: 0,
          DislikeCount: 0,
          Status: approved,
        };
        await apiService.updateBlogPost(updatedPost, post.PostID, Status);
      } else {
        // Create new post
        const newPost = {
          ...formData,
          UserID: UserID,
          BlogID: selectedBlogId,
          LikeCount: 0,
          DislikeCount: 0,
          Status: approved,
        };
        await apiService.createBlogPost(newPost);
      }
      onSave();
    } catch (error) {
      console.error("Error saving post:", error.message);
      // Handle error, show error message, etc.
    }
  };

  return (
    <div className="post-form">
      <div className="post-form-header">
        <div className="flex flex-wrap">
          <div className="w-full md:w-2/3">
            <EditorToolbar />
          </div>
          <div className="w-full md:w-1/3 flex justify-end space-x-2">
            {Status === "0" ? (
              <>
                <button
                  onClick={(e) => handleSubmit(e, 2)}
                  className="bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-600 transition duration-300"
                >
                  <FontAwesomeIcon icon={faSave} className="mr-1" />
                  Publish
                </button>
                <button
                  onClick={(e) => handleSubmit(e, 0)}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                >
                  <FontAwesomeIcon icon={faSave} className="mr-1" />
                  Save
                </button>
              </>
            ) : Status === "3" ? (
              <button
                onClick={(e) => handleSubmit(e, 2)}
                className="bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-600 transition duration-300"
              >
                <FontAwesomeIcon icon={faSave} className="mr-1" />
                Publish
              </button>
            ) : (
              <button
                onClick={(e) => handleSubmit(e, Status)}
                className="bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-600 transition duration-300"
              >
                <FontAwesomeIcon icon={faSave} className="mr-1" />
                Publish
              </button>
            )}
            <button
              onClick={handleShowHtmlModal}
              className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition duration-300"
            >
              <FontAwesomeIcon icon={faEdit} className="mr-1" />
              Edit Html
            </button>
            <button
              onClick={onCancel}
              className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition duration-300"
            >
              <FontAwesomeIcon icon={faTimes} className="mr-1" />
              Cancel
            </button>
          </div>
        </div>
      </div>

      <div className="post-form-body">
        <form onSubmit={handleSubmit}>
          {post && post.PostID && Status !== "0" ? (
            <div className="mb-3">
              <div className="w-full md:w-1/3">
                <select
                  className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  onChange={(e) => handleSelectBlog(e.target.value)}
                  value={selectedBlogId}
                >
                  <option value="" disabled>
                    {selectedBlogTitle}
                  </option>
                  {blogsData?.map((blog) => (
                    <option key={blog.BlogID} value={blog.BlogID}>
                      {blog.Title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : (
            <>
              <div className="mb-3 flex flex-wrap">
                <div className="w-full md:w-1/3 mb-3 md:mb-0">
                  <select
                    className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onChange={(e) => handleSelectBlog(e.target.value)}
                    value={selectedBlogId}
                  >
                    <option value="" disabled>
                      {selectedBlogTitle}
                    </option>
                    {blogsData.map((blog) => (
                      <option key={blog.BlogID} value={blog.BlogID}>
                        {blog.Title}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full md:w-1/3 mb-3 md:mb-0">
                  <input
                    type="text"
                    name="title"
                    placeholder="Title"
                    value={formData.Title}
                    onChange={(e) =>
                      setFormData({ ...formData, Title: e.target.value })
                    }
                    required
                    className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="w-full md:w-1/3">
                  <input
                    type="text"
                    name="seoUrl"
                    placeholder="SEO URL"
                    value={formData.SEOUrl}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        SEOUrl: e.target.value.replace(/\s+/g, "-"),
                      })
                    }
                    required
                    className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </div>
              <div className="mb-3 flex flex-wrap">
                <div className="w-full md:w-1/3 mb-3 md:mb-0">
                  <input
                    type="text"
                    name="metaDescription"
                    placeholder="Meta Description"
                    value={formData.MetaDescription}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        MetaDescription: e.target.value,
                      })
                    }
                    required
                    className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="w-full md:w-1/3 mb-3 md:mb-0">
                  <input
                    type="text"
                    name="metaKeywords"
                    placeholder="Meta Keywords"
                    value={formData.MetaKeywords}
                    onChange={(e) =>
                      setFormData({ ...formData, MetaKeywords: e.target.value })
                    }
                    required
                    className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="w-full md:w-1/3">
                  <input
                    type="text"
                    name="PhotoCredit"
                    placeholder="Image Credit"
                    value={formData.PhotoCredit || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, PhotoCredit: e.target.value })
                    }
                    className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </div>
              <div className="mb-3">
                <div className="flex justify-center items-center border-2 border-dashed border-gray-300 p-4 rounded-lg">
                  <div
                    className={`image-upload-container ${
                      formData.ImageUrl ? "uploaded" : ""
                    }`}
                  >
                    {formData.ImageUrl ? (
                      <div className="uploaded-image relative">
                        <img
                          src={formData.ImageUrl}
                          alt="Uploaded"
                          className="max-w-full h-auto mx-auto"
                        />
                        <button
                          onClick={handleRemoveImage}
                          className="absolute top-0 right-0 bg-red-500 text-white px-2 py-1 rounded-lg hover:bg-red-600 transition duration-300"
                        >
                          Remove Image
                        </button>
                      </div>
                    ) : (
                      <div
                        className="upload-prompt"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={handleImageUpload}
                      >
                        <label className="custom-file-upload cursor-pointer">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            required
                            className="hidden"
                          />
                          <FontAwesomeIcon icon={faUpload} className="mr-2" />
                          Drop your image here or click to upload
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="mb-3">
            <ReactQuill
              value={formData.Content}
              onChange={handleChange}
              modules={modules}
              formats={formats}
              className="bg-white"
              placeholder="Write your content here"
              required
            />
          </div>

          <button type="submit" className="hidden"></button>
        </form>
      </div>

      {showHtmlModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white p-6 rounded-lg shadow-lg z-10 w-full max-w-3xl">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Edit HTML Content</h2>
              <button
                onClick={() => setShowHtmlModal(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition duration-300"
              >
                Close
              </button>
            </div>
            <textarea
              rows={10}
              value={htmlContent}
              onChange={handleHtmlContentChange}
              className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div className="flex justify-end space-x-2 mt-4">
              <button
                onClick={() => setShowHtmlModal(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition duration-300"
              >
                Close
              </button>
              <button
                onClick={handleSaveHtmlContent}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PostForm;
