import React from "react";
import { baseUrl } from "../../config";

const AboutUsSection = () => {
  return (
    <div className="bg-gray-100 py-12" id="about-us">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">
          About GeniusNexa
        </h2>
        <div className="flex flex-wrap items-center">
          <div className="bg-slate-100 w-full md:w-1/2 mb-8 md:mb-0 rounded-lg p-2">
            <img
              src={`${baseUrl}/images/GeniusNexachart.png`}
              alt="About GeniusNexa"
            />
          </div>
          <div className="w-full md:w-1/2 md:pl-8">
            <p className="text-lg mb-4">
              Welcome to GeniusNexa, where we push the limits of innovation to
              transform industries. Our mission is to revolutionize the way we
              live and work through cutting-edge technologies.
            </p>
            <p className="text-lg mb-4">
              From artificial intelligence to blockchain, our solutions enhance
              efficiency and improve processes. Join us on a journey to explore
              the incredible advancements and possibilities that GeniusNexa has
              to offer.
            </p>
            <p className="text-lg mb-4">
              Discover how our technologies are shaping the future and unlocking
              opportunities that were once unimaginable. The future is here, and
              it's powered by GeniusNexa.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
