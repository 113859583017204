import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { trackEvent } from "../components/trackingUtils";
import { baseUrl } from "../config";
import "./Tools.css";

const tools = [
  {
    id: "seonavigator",
    name: "SEO Navigator",
    image: "/images/seonavigator.png",
    description:
      "SEO Navigator is a comprehensive tool designed to help businesses and individuals optimize their websites for search engines. It provides valuable insights into keyword performance, backlink analysis, on-page SEO, and technical site audits. With user-friendly features, SEO Navigator simplifies the process of improving search engine rankings, driving organic traffic, and enhancing overall website visibility. Whether you're a seasoned SEO professional or just starting, this tool equips you with the data and strategies needed to navigate the complexities of SEO and achieve better online success.",
  },
  {
    id: "imageconverter",
    name: "Image Converter",
    image: "/images/ImageConverter.png",
    description:
      "The Image converter is a versatile React-based tool designed to streamline the conversion of images into formats optimized for various social media platforms, including Twitter, Facebook, WhatsApp, and LinkedIn. It offers a seamless user experience with real-time feedback during image processing, ensuring that users can quickly and efficiently prepare images for multiple platforms without the need for separate tools. Additionally, the component includes robust error handling and integrates SEO optimization to enhance discoverability, making it an essential resource for content creators and social media managers.",
  },
];

const Tools = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    trackEvent("PageLoad", "", "Tools");
    // Simulate loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 1 second delay
    return () => clearTimeout(timer);
  }, []);

  const handleCardClick = (id) => {
    history.push(`/tools/${id}`);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-blue-500"></div>
      </div>
    );
  }

  const title =
    "Explore Innovative Tools to Enhance Your Online Presence | GeniusNexa";
  const description =
    "Discover a range of innovative tools designed to optimize and enhance your online presence. From in-depth analyses to powerful optimizations, find the perfect tool to boost performance and achieve your digital goals.";
  const keywords =
    "online optimization tools, digital marketing tools, performance enhancement, innovative tools, online presence improvement, GeniusNexa";

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={`${baseUrl}/images/tools.png`} />
        <meta property="og:url" content={`${baseUrl}/tools`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className="container mx-auto py-12 px-4">
        <div className="tools-list">
          <h2 className="text-3xl font-bold text-center mb-8">Our Tools</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {tools.map((tool) => (
              <div
                key={tool.id}
                className="bg-white shadow-md rounded-lg overflow-hidden transform transition duration-300 hover:scale-105 cursor-pointer"
                onClick={() => handleCardClick(tool.id)}
              >
                <img
                  src={tool.image}
                  alt={tool.name}
                  className="w-full h-48 object-cover"
                />
                <div className="p-4">
                  <h3 className="text-xl font-semibold mb-2">{tool.name}</h3>
                  <p className="text-gray-700">{tool.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Tools;
