import React, { useState } from "react";

const EditUserForm = ({ user, onSave, onCancel }) => {
  const [editedUser, setEditedUser] = useState({ ...user });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    onSave(editedUser);
  };

  return (
    <div className="edit-user-form p-4 bg-white rounded-lg shadow-lg">
      <input
        type="text"
        name="UserName"
        value={editedUser.UserName}
        onChange={handleChange}
        placeholder="Name"
        className="w-full p-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <input
        type="email"
        name="UserEmail"
        value={editedUser.UserEmail}
        onChange={handleChange}
        placeholder="Email"
        className="w-full p-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <select
        name="Role"
        value={editedUser.Role}
        onChange={handleChange}
        className="w-full p-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <option value="Admin">Admin</option>
        <option value="User">User</option>
      </select>
      <div className="flex justify-end space-x-4">
        <button
          onClick={handleSave}
          className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300"
        >
          Save
        </button>
        <button
          onClick={onCancel}
          className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition duration-300"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default EditUserForm;
