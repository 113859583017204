import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrashAlt,
  faPlus,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import apiService from "../../ApiService";
import EditUserForm from "./EditUserForm";
import { useHistory } from "react-router-dom";

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const [statusValue, setStatusValue] = useState("ALL");
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [editingUserId, setEditingUserId] = useState(null);
  const history = useHistory();

  const radios = [
    { name: "ALL", value: "ALL" },
    { name: "Admin", value: "Admin" },
    { name: "Users", value: "User" },
  ];

  const fetchUsers = useCallback(async (page, pageSize, role, search) => {
    try {
      const response = await apiService.getUsers(page, pageSize, role, search);
      setUsers(response.records);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  }, []);

  useEffect(() => {
    fetchUsers(currentPage, itemsPerPage, statusValue, searchTitle);
  }, [fetchUsers, currentPage, itemsPerPage, statusValue, searchTitle]);

  const handleSearchInputChange = (e) => {
    setSearchTitle(e.target.value);
    setCurrentPage(1);
  };

  const handleRadioChange = (value) => {
    setStatusValue(value);
    setCurrentPage(1);
  };

  const handleAddUser = () => {
    history.push("/register");
  };

  const handleEditUser = (userId) => {
    setEditingUserId(userId);
  };

  const handleDeleteUser = (userId) => {
    setDeleteUserId(userId);
    setShowDeleteModal(true);
  };

  const handleSelectItemsPerPage = (number) => {
    setItemsPerPage(parseInt(number, 10));
    setCurrentPage(1);
  };

  const confirmDeletePost = async () => {
    try {
      await apiService.deleteUser(deleteUserId);
      // Refresh the list of users after deletion
      fetchUsers(currentPage, itemsPerPage, statusValue, searchTitle);
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSaveEdit = async (updatedUser) => {
    try {
      const response = await apiService.updateUser(updatedUser);
      if (response && response.message === "User updated successfully.") {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.UserID === updatedUser.UserID ? updatedUser : user
          )
        );
        setEditingUserId(null);
      } else {
        console.error("Failed to update user:", response);
        alert("Failed to update user. Please try again.");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      alert("An error occurred while updating the user. Please try again.");
    }
  };

  const handleCancelEdit = () => {
    setEditingUserId(null);
  };

  const renderPaginationItems = () => {
    const items = [];
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, currentPage + 1);

    if (currentPage > 1) {
      items.push(
        <button
          key="first"
          onClick={() => handlePageChange(1)}
          className="px-3 py-1 border rounded-l-lg"
        >
          First
        </button>
      );
      items.push(
        <button
          key="prev"
          onClick={() => handlePageChange(currentPage - 1)}
          className="px-3 py-1 border"
        >
          Prev
        </button>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <button
          key={i}
          className={`px-3 py-1 border ${
            i === currentPage ? "bg-blue-500 text-white" : ""
          }`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    if (currentPage < totalPages) {
      items.push(
        <button
          key="next"
          onClick={() => handlePageChange(currentPage + 1)}
          className="px-3 py-1 border"
        >
          Next
        </button>
      );
      items.push(
        <button
          key="last"
          onClick={() => handlePageChange(totalPages)}
          className="px-3 py-1 border rounded-r-lg"
        >
          Last
        </button>
      );
    }

    return items;
  };

  return (
    <>
      <div className="container mx-auto py-12 px-4">
        <div className="mb-8">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
            <input
              value={searchTitle}
              type="text"
              placeholder="Search..."
              className="w-full md:w-1/3 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              onChange={handleSearchInputChange}
            />
            <div className="flex flex-col md:flex-row gap-4">
              <div className="flex gap-2">
                {radios.map((radio, idx) => (
                  <button
                    key={idx}
                    className={`px-4 py-2 rounded-lg ${
                      statusValue === radio.value
                        ? "bg-blue-500 text-white"
                        : "bg-gray-200"
                    }`}
                    onClick={() => handleRadioChange(radio.value)}
                  >
                    {radio.name}
                  </button>
                ))}
              </div>
              <button
                onClick={handleAddUser}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Add
              </button>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {users?.length > 0 ? (
            users.map((user) => (
              <div
                key={user.UserID}
                className="bg-white p-4 rounded-lg shadow-lg"
              >
                {editingUserId === user.UserID ? (
                  <EditUserForm
                    user={user}
                    onSave={handleSaveEdit}
                    onCancel={handleCancelEdit}
                  />
                ) : (
                  <div
                    className={`user-card ${
                      user.Role === "admin" ? "bg-gray-100" : "bg-white"
                    }`}
                  >
                    <div className="flex items-center mb-4">
                      {user.UserImage ? (
                        <img
                          src={user.UserImage}
                          alt="User Avatar"
                          className="w-16 h-16 rounded-full"
                        />
                      ) : (
                        <FontAwesomeIcon icon={faUser} size="3x" />
                      )}
                    </div>
                    <div className="text-center">
                      <h3 className="text-xl font-semibold">{user.UserName}</h3>
                      <p className="text-gray-500">{user.UserEmail}</p>
                      <div className="flex justify-center space-x-2 mt-4">
                        <button
                          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                          onClick={() => handleEditUser(user.UserID)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                        <button
                          className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-300"
                          onClick={() => handleDeleteUser(user.UserID)}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div>No users found</div>
          )}
        </div>
        <div className="flex justify-between items-center mt-8">
          <div>
            <select
              value={itemsPerPage}
              onChange={(e) => handleSelectItemsPerPage(e.target.value)}
              className="p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {[6, 12, 24].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
          <div className="flex space-x-2">{renderPaginationItems()}</div>
        </div>
      </div>

      <div
        className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ${
          showDeleteModal ? "block" : "hidden"
        }`}
      >
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-xl font-semibold mb-4">Confirm Deletion</h2>
          <p className="mb-4">Are you sure you want to delete this user?</p>
          <div className="flex justify-end space-x-4">
            <button
              onClick={handleCancelDelete}
              className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition duration-300"
            >
              Cancel
            </button>
            <button
              onClick={confirmDeletePost}
              className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-300"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminUsers;
