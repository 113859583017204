import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faUser,
  faChevronDown,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import UserContext from "../../contexts/UserContext";
import { useMediaQuery } from "react-responsive";
import { baseUrl } from "../../config";
import "./Header.css"; // You can remove this if you no longer need custom CSS

const Header = ({ blogs }) => {
  const { user, isAuthenticated, logout } = useContext(UserContext);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  const handleDropdownClick = (name) => {
    setOpenDropdown((prev) => (prev === name ? null : name));
  };

  return (
    <>
      <nav className="bg-white shadow-md z-50 fixed w-full">
        <div className="container mx-auto flex justify-between items-center py-4">
          <Link to="/" className="flex items-center">
            <img
              src={baseUrl + `/images/logo.png`}
              height="40"
              className="h-10"
              alt="Logo"
            />
          </Link>
          <button
            className="text-gray-500 focus:outline-none lg:hidden"
            onClick={toggleOffcanvas}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          <div
            className={`lg:flex items-center ${
              isTabletOrMobile ? "hidden" : ""
            }`}
          >
            <Link to="/services" className="nav-link menu-item px-4 py-2">
              Services
            </Link>
            <div className="relative group">
              <button className="nav-link menu-item px-4 py-2 flex items-center">
                Blogs <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
              </button>
              <div className="absolute hidden group-hover:block bg-white shadow-lg w-48">
                {blogs.map((blog, index) => (
                  <React.Fragment key={blog.SEOUrl}>
                    <Link
                      to={`/blogs/${blog.SEOUrl}`}
                      className="block px-4 py-2 hover:bg-gray-100"
                      onClick={() => setShowOffcanvas(false)}
                    >
                      {blog.Title}
                    </Link>
                    {index < blogs.length - 1 && (
                      <div className="border-t border-gray-200"></div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <Link to="/contact-us" className="nav-link menu-item px-4 py-2">
              Contact Us
            </Link>
            <div className="relative group">
              <button className="nav-link menu-item px-4 py-2 flex items-center">
                Tools <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
              </button>
              <div className="absolute hidden group-hover:block bg-white shadow-lg w-48">
                <Link
                  to={`/tools/comparexpert`}
                  className="block px-4 py-2 hover:bg-gray-100"
                  onClick={() => setShowOffcanvas(false)}
                >
                  CompareXpert
                </Link>
                <div className="border-t border-gray-200"></div>
                <Link
                  to={`/tools/seonavigator`}
                  className="block px-4 py-2 hover:bg-gray-100"
                  onClick={() => setShowOffcanvas(false)}
                >
                  SEO Navigator
                </Link>
                <div className="border-t border-gray-200"></div>
                <Link
                  to={`/tools/imageconverter`}
                  className="block px-4 py-2 hover:bg-gray-100"
                  onClick={() => setShowOffcanvas(false)}
                >
                  Image Converter
                </Link>
              </div>
            </div>
            {isAuthenticated ? (
              <div className="relative group">
                <div className="nav-link menu-item px-4  flex items-center cursor-pointer">
                  <div className="user-avatar-container">
                    {user && user.userImage ? (
                      <img
                        src={user.userImage}
                        alt="User Avatar"
                        className="user-avatar h-8 w-8 rounded-full"
                      />
                    ) : (
                      <FontAwesomeIcon icon={faUser} className="user-icon" />
                    )}
                  </div>
                </div>
                <div className="absolute hidden group-hover:block bg-white shadow-lg w-48">
                  {user && user.role === "admin" && (
                    <>
                      <Link
                        to="/admin"
                        className="block px-4 py-2 hover:bg-gray-100"
                        onClick={() => setShowOffcanvas(false)}
                      >
                        Dashboard
                      </Link>
                      <div className="border-t border-gray-200"></div>
                    </>
                  )}
                  <Link
                    to="/settings"
                    className="block px-4 py-2 hover:bg-gray-100"
                    onClick={() => setShowOffcanvas(false)}
                  >
                    Settings
                  </Link>
                  <div className="border-t border-gray-200"></div>
                  <button
                    onClick={() => {
                      logout();
                      setShowOffcanvas(false);
                    }}
                    className="block px-4 py-2 w-full text-left hover:bg-gray-100"
                  >
                    Logout
                  </button>
                </div>
              </div>
            ) : (
              <Link
                to="/login"
                className="nav-link menu-item px-4 py-2"
                onClick={() => setShowOffcanvas(false)}
              >
                Sign Up
              </Link>
            )}
          </div>
        </div>
      </nav>

      {showOffcanvas && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-50">
          <div className="fixed inset-y-0 left-0 bg-white w-64 p-4">
            <button
              className="text-gray-500 focus:outline-none"
              onClick={toggleOffcanvas}
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
            <nav className="mt-4">
              <Link
                to="/services"
                className="block px-4 py-2 hover:bg-gray-100"
                onClick={() => setShowOffcanvas(false)}
              >
                Services
              </Link>
              <div className="border-t border-gray-200"></div>
              <div>
                <button
                  className="px-4 py-2 w-full text-left hover:bg-gray-100 flex items-center"
                  onClick={() => handleDropdownClick("blogs")}
                >
                  Blogs{" "}
                  <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
                </button>
                {openDropdown === "blogs" && (
                  <div className="pl-4">
                    {blogs.map((blog, index) => (
                      <React.Fragment key={blog.SEOUrl}>
                        <Link
                          to={`/blogs/${blog.SEOUrl}`}
                          className="block px-4 py-2 hover:bg-gray-100"
                          onClick={() => setShowOffcanvas(false)}
                        >
                          {blog.Title}
                        </Link>
                        {index < blogs.length - 1 && (
                          <div className="border-t border-gray-200"></div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                )}
              </div>
              <div className="border-t border-gray-200"></div>
              <Link
                to="/contact-us"
                className="block px-4 py-2 hover:bg-gray-100"
                onClick={() => setShowOffcanvas(false)}
              >
                Contact Us
              </Link>
              <div className="border-t border-gray-200"></div>
              <div>
                <button
                  className="px-4 py-2 w-full text-left hover:bg-gray-100 flex items-center"
                  onClick={() => handleDropdownClick("tools")}
                >
                  Tools{" "}
                  <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
                </button>
                {openDropdown === "tools" && (
                  <div className="pl-4">
                    <Link
                      to="/tools/comparexpert"
                      className="block px-4 py-2 hover:bg-gray-100"
                      onClick={() => setShowOffcanvas(false)}
                    >
                      CompareXpert
                    </Link>
                    <div className="border-t border-gray-200"></div>
                    <Link
                      to="/tools/seonavigator"
                      className="block px-4 py-2 hover:bg-gray-100"
                      onClick={() => setShowOffcanvas(false)}
                    >
                      SEO Navigator
                    </Link>
                    <div className="border-t border-gray-200"></div>
                    <Link
                      to="/tools/imageconverter"
                      className="block px-4 py-2 hover:bg-gray-100"
                      onClick={() => setShowOffcanvas(false)}
                    >
                      Image Converter
                    </Link>
                  </div>
                )}
              </div>
              {isAuthenticated ? (
                <>
                  <div className="divider my-4"></div>
                  <div>
                    <button
                      className="px-4 py-2 w-full text-left hover:bg-gray-100 flex items-center"
                      onClick={() => handleDropdownClick("admin")}
                    >
                      Admin{" "}
                      <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
                    </button>
                    {openDropdown === "admin" && (
                      <div className="pl-4">
                        <Link
                          to="/admin"
                          className="block px-4 py-2 hover:bg-gray-100"
                          onClick={() => setShowOffcanvas(false)}
                        >
                          Dashboard
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="border-t border-gray-200"></div>
                  <button
                    onClick={() => {
                      logout();
                      setShowOffcanvas(false);
                    }}
                    className="block px-4 py-2 w-full text-left hover:bg-gray-100"
                  >
                    Logout
                  </button>
                </>
              ) : (
                <Link
                  to="/login"
                  className="block px-4 py-2 hover:bg-gray-100"
                  onClick={() => setShowOffcanvas(false)}
                >
                  Sign Up
                </Link>
              )}
            </nav>
            {isAuthenticated && (
              <div className="mt-4">
                {user && user.userImage ? (
                  <img
                    src={user.userImage}
                    alt="User Avatar"
                    className="h-10 w-10 rounded-full"
                  />
                ) : (
                  <FontAwesomeIcon icon={faUser} className="h-10 w-10" />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
