import React, { useEffect, useState, useCallback } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import apiService from "../../ApiService"; // Import your apiService
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AdminReports = () => {
  const [eventDetails, setEventDetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Function to format dates to 'YYYY-MM-DD'
  const formatDate = (date) => {
    if (!date) return null;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Memoize the fetchEventData function to avoid recreating it on every render
  const fetchEventData = useCallback(async () => {
    try {
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      const response = await apiService.getEvents(
        formattedStartDate,
        formattedEndDate
      );

      if (response.status === "success") {
        setEventDetails(response.data); // Store the detailed event data for the table
      } else {
        console.error("Error fetching events:", response);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  }, [startDate, endDate]); // Dependency array with startDate and endDate

  useEffect(() => {
    fetchEventData();
  }, [fetchEventData]); // Add fetchEventData to the dependency array

  // Function to handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter the eventDetails based on the search query
  const filteredEvents = eventDetails.filter((item) =>
    Object.values(item).some((val) =>
      val.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  // Group data by PageUrl to count event occurrences for the graph
  const aggregatedData = filteredEvents.reduce((acc, item) => {
    const found = acc.find((data) => data.LinkText === item.LinkText);
    if (found) {
      found.Clicks += 1;
    } else {
      acc.push({
        LinkText: item.LinkText,
        Clicks: 1,
      });
    }
    return acc;
  }, []);

  return (
    <div className="container mx-auto py-12 px-4">
      <div className="mb-8">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="w-full md:w-1/3 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div className="flex flex-col md:flex-row gap-4">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy-MM-dd"
              className="form-control p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholderText="Start Date"
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="yyyy-MM-dd"
              className="form-control p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholderText="End Date"
            />
            <button
              onClick={fetchEventData}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
            >
              Refresh
            </button>
          </div>
        </div>
      </div>
      <div className="mb-8">
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={aggregatedData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="LinkText" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Clicks" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div>
        <h3 className="text-xl font-semibold mb-4">Details Table</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white rounded-lg shadow-lg">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">EventType</th>
                <th className="py-2 px-4 border-b">PageUrl</th>
                <th className="py-2 px-4 border-b">UserIp</th>
                <th className="py-2 px-4 border-b">ReferrerUrl</th>
                <th className="py-2 px-4 border-b">ClickUrl</th>
                <th className="py-2 px-4 border-b">LinkText</th>
                <th className="py-2 px-4 border-b">CreatedAt</th>
              </tr>
            </thead>
            <tbody>
              {filteredEvents.length > 0 ? (
                filteredEvents.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b">{item.EventType}</td>
                    <td className="py-2 px-4 border-b">{item.PageUrl}</td>
                    <td className="py-2 px-4 border-b">{item.UserIp}</td>
                    <td className="py-2 px-4 border-b">{item.ReferrerUrl}</td>
                    <td className="py-2 px-4 border-b">{item.ClickUrl}</td>
                    <td className="py-2 px-4 border-b">{item.LinkText}</td>
                    <td className="py-2 px-4 border-b">{item.CreatedAt}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="py-2 px-4 border-b text-center">
                    No events found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminReports;
