// Services.js

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faChartLine,
  faMobileAlt,
  faCloud,
  faShieldAlt,
  faCode,
  faUser,
  faFilePdf,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons";

const iconMap = {
  Development: faCode,
  Analytics: faChartLine,
  Mobile: faMobileAlt,
  Cloud: faCloud,
  Security: faShieldAlt,
  Consulting: faUser,
  PDF: faFilePdf,
  Data: faDatabase,
};

const ServicesSection = ({ services }) => {
  return (
    <div className="bg-white-100 py-12" id="services">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Our Services</h2>
        <div className="flex flex-wrap -mx-4">
          {services.map((service, index) => (
            <div key={index} className="w-full sm:w-1/2 md:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 rounded-lg shadow-lg h-full flex flex-col justify-between transform transition-transform duration-300 hover:scale-105 hover:shadow-xl">
                <div className="text-center">
                  <FontAwesomeIcon
                    icon={iconMap[service.icon] || faCogs}
                    size="3x"
                    className="text-blue-500 mb-4"
                  />
                  <h3 className="text-xl font-semibold mb-4">
                    {service.title}
                  </h3>
                  <p className="text-gray-700">{service.description}</p>
                </div>
                <div className="mt-4 text-center">
                  <a
                    href={`services/${service.url}`}
                    className="text-blue-500 hover:text-blue-700 transition duration-300"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
