// NotFound.js

import React from "react";
import { Helmet } from "react-helmet"; // Import Helmet for managing the document head
import { Link } from "react-router-dom"; // Import Link for navigation
import { FaHome } from "react-icons/fa"; // Import a home icon from react-icons

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <Helmet>
        <title>404 - Page Not Found</title>
        <meta
          name="description"
          content="Sorry, the page you are looking for does not exist. Check the URL or return to the homepage."
        />
        <meta
          name="keywords"
          content="404, page not found, error, GeniusNexa"
        />
        <meta property="og:title" content="404 - Page Not Found" />
        <meta
          property="og:description"
          content="Sorry, the page you are looking for does not exist. Check the URL or return to the homepage."
        />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="text-center p-6 bg-white rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
        <p className="text-lg mb-4">
          The page you are looking for does not exist.
        </p>
        <p>
          <Link
            to="/"
            className="text-blue-500 hover:text-blue-700 transition duration-300"
          >
            <FaHome className="inline-block mr-2" /> Return to Homepage
          </Link>
        </p>
      </div>
    </div>
  );
};

export default NotFound;
