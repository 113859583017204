import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import apiService from "../../ApiService";

const Logout = () => {
  const [cookies, , removeCookie] = useCookies(["sessionId"]);
  const history = useHistory();

  useEffect(() => {
    const logoutUser = async () => {
      try {
        const sessionId = cookies.sessionId;
        if (!sessionId) {
          history.push("/login");
          return;
        }

        const response = await apiService.logout(sessionId);

        if (response.success) {
          removeCookie("sessionId", { path: "/" });
          history.push("/login");
        } else {
          console.error("Logout failed:", response.message);
        }
      } catch (error) {
        console.error("Error during logout:", error);
      }
    };

    logoutUser();
  }, [cookies, history, removeCookie]);

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-blue-500 mb-4"></div>
        <p className="text-lg font-semibold">Logging out...</p>
      </div>
    </div>
  );
};

export default Logout;
