import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import apiService from "../ApiService";
import { baseUrl } from "../config";
import { trackEvent } from "../components/trackingUtils";

const SeoMetaChecker = () => {
  const [url, setUrl] = useState("");
  const [seoData, setSeoData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [seoScore, setScore] = useState(0);
  const [scoredetails, setScoreDetails] = useState();

  useEffect(() => {
    trackEvent("PageLoad", "", "SEOMetaChecker");
  }, []);

  const handleFetchSeoData = async () => {
    setLoading(true);
    try {
      const response = await apiService.fetchSEOData(url); // Assuming apiService is correctly implemented
      trackEvent("Click", url, response.status);
      if (response.status === "success") {
        setSeoData(response.audit_report);
        setScore(response.audit_report.seo_score);
        setScoreDetails(response.score_details);
        setError(null);
      } else {
        setError("Failed to fetch SEO data");
        setSeoData(null);
      }
    } catch (error) {
      setError("Failed to fetch SEO data");
      setSeoData(null);
    } finally {
      setLoading(false);
    }
  };

  // Function to determine the color based on score
  const getScoreColor = () => {
    const score = seoScore;
    if (score >= 20) return "bg-green-500"; // High score
    if (score >= 10) return "bg-yellow-500"; // Medium score
    return "bg-red-500"; // Low score
  };

  const title = "SEO Navigator - Free Website Analysis & Insights | GeniusNexa";
  const description =
    "Perform a comprehensive SEO audit with our free SEO Navigator. Identify website issues, optimize content, and improve rankings. Start your SEO analysis now.";
  const keywords =
    "SEO Navigator, free SEO analysis, website SEO audit, SEO optimization, improve SEO rankings, SEO tool, SEO checker, website analysis tool";

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={`${baseUrl}/images/seonavigator.png`}
        />
        <meta property="og:url" content={`${baseUrl}/tool/seonavigator`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className="container mx-auto py-12 px-4">
        {seoData && (
          <div className={`p-4 rounded-lg text-white ${getScoreColor()} mb-4`}>
            <h3 className="text-xl font-semibold">SEO Score: {seoScore}</h3>
            <p>Based on SEO best practices</p>
          </div>
        )}
        <h2 className="text-3xl font-bold mt-4 mb-8 text-center">
          SEO Navigator
        </h2>
        <div className="mb-8">
          <label
            htmlFor="urlInput"
            className="block text-gray-700 font-semibold mb-2"
          >
            Enter URL:
          </label>
          <div className="flex">
            <input
              type="text"
              id="urlInput"
              className="flex-grow px-4 py-2 border rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="https://www.example.com"
              aria-label="Enter URL"
            />
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded-r-lg hover:bg-blue-600 transition duration-300"
              type="button"
              onClick={handleFetchSeoData}
              disabled={!url || loading}
            >
              {loading ? (
                <div className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full border-t-transparent border-white"></div>
              ) : (
                "Fetch SEO Data"
              )}
            </button>
          </div>
        </div>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {seoData && (
          <div className="seo-data">
            <div className="bg-white p-4 rounded-lg shadow-lg mb-4">
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="text-lg font-semibold">Title:</h3>
                  <p>{seoData.title}</p>
                </div>
                <span
                  className={`px-2 py-1 rounded-full text-white ${
                    scoredetails.title_length_score >= 10
                      ? "bg-green-500"
                      : scoredetails.title_length_score >= 5
                      ? "bg-yellow-500"
                      : "bg-red-500"
                  }`}
                >
                  {scoredetails.title_length_score}
                </span>
              </div>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-lg mb-4">
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="text-lg font-semibold">Meta Description:</h3>
                  <p>{seoData.meta_description}</p>
                </div>
                <span
                  className={`px-2 py-1 rounded-full text-white ${
                    scoredetails.meta_description_present_score >= 10
                      ? "bg-green-500"
                      : scoredetails.meta_description_present_score >= 5
                      ? "bg-yellow-500"
                      : "bg-red-500"
                  }`}
                >
                  {scoredetails.meta_description_present_score}
                </span>
              </div>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-lg mb-4">
              <h3 className="text-lg font-semibold">Meta Keywords:</h3>
              <ul className="list-disc list-inside">
                {seoData.meta_keywords.map((keyword, index) => (
                  <li key={index}>{keyword}</li>
                ))}
              </ul>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-lg mb-4">
              <h3 className="text-lg font-semibold">Open Graph Properties:</h3>
              <ul className="list-disc list-inside">
                <li>
                  <strong>Image:</strong>{" "}
                  <img
                    src={seoData.og_properties.og_image}
                    alt="OG "
                    className="w-full h-auto rounded-lg"
                  />
                </li>
                <li>
                  <strong>Title:</strong> {seoData.og_properties.og_title}
                </li>
                <li>
                  <strong>URL:</strong> {seoData.og_properties.og_url}
                </li>
              </ul>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-lg mb-4">
              <h3 className="text-lg font-semibold">Twitter Properties:</h3>
              <ul className="list-disc list-inside">
                <li>
                  <strong>Title:</strong>{" "}
                  {seoData.twitter_properties.twitter_title}
                </li>
                <li>
                  <strong>Description:</strong>{" "}
                  {seoData.twitter_properties.twitter_description}
                </li>
                <li>
                  <strong>Creator:</strong>{" "}
                  {seoData.twitter_properties.twitter_creator}
                </li>
                <li>
                  <strong>Image:</strong>{" "}
                  <img
                    src={seoData.twitter_properties.twitter_image}
                    alt="Twitter "
                    className="w-full h-auto rounded-lg"
                  />
                </li>
                <li>
                  <strong>Site:</strong>{" "}
                  {seoData.twitter_properties.twitter_site}
                </li>
                <li>
                  <strong>Card:</strong>{" "}
                  {seoData.twitter_properties.twitter_card}
                </li>
              </ul>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-lg mb-4">
              <h3 className="text-lg font-semibold">WhatsApp Properties:</h3>
              <ul className="list-disc list-inside">
                {seoData.whatsapp_properties.map((link, index) => (
                  <li key={index}>
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      {link}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-lg mb-4">
              <h3 className="text-lg font-semibold">Teams Properties:</h3>
              <ul className="list-disc list-inside">
                {seoData.teams_properties.map((link, index) => (
                  <li key={index}>
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      {link}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-lg mb-4">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-lg font-semibold">Internal Links:</h3>
                  <ul className="list-disc list-inside">
                    {seoData.internal_links.map((link, index) => (
                      <li key={index}>
                        <a
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {link}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <span
                  className={`px-2 py-1 rounded-full text-white ${
                    scoredetails.num_internal_links_score >= 10
                      ? "bg-green-500"
                      : scoredetails.num_internal_links_score >= 5
                      ? "bg-yellow-500"
                      : "bg-red-500"
                  }`}
                >
                  {scoredetails.num_internal_links_score}
                </span>
              </div>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-lg mb-4">
              <h3 className="text-lg font-semibold">External Links:</h3>
              <ul className="list-disc list-inside">
                {seoData.external_links.map((link, index) => (
                  <li key={index}>
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      {link}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {/* Display a message while loading */}
        {loading && (
          <div className="text-center">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-blue-500"></div>
            <p className="mt-4">Loading SEO data...</p>
          </div>
        )}
        {/* Display a message if no SEO data available */}
        {!loading && !seoData && (
          <div className="text-center">
            <p>
              No SEO data available. Enter a valid URL and click 'Fetch SEO
              Data'.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default SeoMetaChecker;
