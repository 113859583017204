// src/components/Footer.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const handleEmailClick = () => {
    window.location.href = "mailto:support@geniusnexa.com";
  };

  return (
    <footer
      className=" text-white py-12 bg-cover bg-center"
      style={{ backgroundImage: "url('/images/footer2.png')" }}
    >
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/3 px-4 mb-8 md:mb-0">
            <h5 className="text-lg font-semibold mb-4">About GeniusNexa</h5>
            <p className="text-sm leading-relaxed">
              GeniusNexa is a leading software service company specializing in
              innovative digital solutions. We are committed to helping
              businesses thrive in the digital age with our expert services.
            </p>
          </div>
          <div className="w-full md:w-1/3 px-4 mb-8 md:mb-0">
            <h5 className="text-lg font-semibold mb-4">Quick Links</h5>
            <ul className="list-none space-y-2">
              <li>
                <a href="/#services" className="text-white hover:text-gray-400">
                  Services
                </a>
              </li>
              <li>
                <a href="/#about-us" className="text-white hover:text-gray-400">
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="/#contact-us"
                  className="text-white hover:text-gray-400"
                >
                  Contact
                </a>
              </li>
              <li>
                <a
                  href="/privacy-policy"
                  className="text-white hover:text-gray-400"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/3 px-4">
            <h5 className="text-lg font-semibold mb-4">Contact Us</h5>
            <ul className="list-none space-y-2">
              <li className="flex items-center">
                <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                <a
                  href="mailto:support@geniusnexa.com"
                  onClick={handleEmailClick}
                  className="text-white hover:text-gray-400"
                >
                  support@geniusnexa.com
                </a>
              </li>
              <li className="flex items-center">
                <FontAwesomeIcon icon={faPhone} className="mr-2" />
                <span>+1 (562) 743-7986</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-8 text-center">
          <p className="text-sm">
            &copy; {new Date().getFullYear()} GeniusNexa. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
