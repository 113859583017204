import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { baseUrl } from "../../config";
import { trackEvent } from "../../components/trackingUtils";

const Careers = () => {
  // Track page load event
  useEffect(() => {
    try {
      trackEvent("PageLoad", "", "Careers"); // Track the page load event
    } catch (error) {
      console.error("Error tracking page load event:", error);
    }
  }, []);

  const title = "Careers at GeniusNexa | Join Our Team";
  const description =
    "Explore exciting career opportunities at GeniusNexa. Join our innovative team and grow your career. Apply now to be part of a dynamic, diverse workplace!";
  const keywords =
    "careers, job openings, join our team, career opportunities, employment, jobs, vacancies, work with us, job listings";

  return (
    <div className="career-page">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={`${baseUrl}/images/careers.png`} />
        <meta property="og:url" content={`${baseUrl}/careers`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="container mx-auto py-12 px-4">
        <h1 className="text-3xl font-bold text-center mb-8">Careers</h1>
        <p className="text-center mb-8">Explore our current job openings:</p>

        <div className="job-list grid gap-8">
          <JobPosting
            title=".NET Developer"
            description="We are looking for a skilled .NET developer to join our team. The ideal candidate will have experience in building scalable web applications using ASP.NET MVC and C#."
            requirements={[
              "Proficiency in ASP.NET MVC and C#",
              "Experience with SQL Server and Entity Framework",
              "Understanding of front-end technologies (HTML5, CSS3, JavaScript)",
            ]}
            onClick={() => trackEvent("JobClick", ".NET Developer")}
          />

          <JobPosting
            title="Python Developer"
            description="Join our Python development team to build robust web applications and backend services. Ideal candidates should have experience with Django or Flask frameworks."
            requirements={[
              "Proficiency in Python and Django or Flask",
              "Experience with RESTful APIs and ORM libraries",
              "Familiarity with front-end technologies (HTML, CSS, JavaScript)",
            ]}
            onClick={() => trackEvent("JobClick", "Python Developer")}
          />

          <JobPosting
            title="Data Scientist"
            description="We are seeking a talented Data Scientist to analyze large datasets and develop machine learning models. Candidates should have expertise in statistical analysis and programming languages like Python or R."
            requirements={[
              "Strong knowledge of statistical analysis and machine learning techniques",
              "Proficiency in Python or R programming languages",
              "Experience with data visualization tools (e.g., Tableau, Matplotlib)",
            ]}
            onClick={() => trackEvent("JobClick", "Data Scientist")}
          />
        </div>
      </div>
    </div>
  );
};

const JobPosting = ({ title, description, requirements, onClick }) => (
  <div className="job-posting bg-white p-6 rounded-lg shadow-lg">
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="mb-4">{description}</p>
    <h4 className="font-semibold mb-2">Requirements:</h4>
    <ul className="list-disc list-inside mb-4">
      {requirements.map((requirement, index) => (
        <li key={index}>{requirement}</li>
      ))}
    </ul>
    <p>
      Interested in this position? Please send your details to{" "}
      <a
        onClick={onClick}
        href="mailto:support@geniusnexa.com"
        className="text-blue-500 hover:underline"
      >
        support@geniusnexa.com
      </a>
      .
    </p>
  </div>
);

export default Careers;
