import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { baseUrl } from "../../config";
import { trackEvent } from "../../components/trackingUtils";

const ContactUs = () => {
  useEffect(() => {
    trackEvent("PageLoad", "", "ContactUs"); // Track the page load event
  }, []);

  const title = "Contact GeniusNexa - Reach Out for Tech Insights";
  const description =
    "Get in touch with GeniusNexa for the latest tech insights, tutorials, and reviews. Contact our Chief Executive Officer, Renukumari Senthil, for more information.";
  const keywords =
    "contact GeniusNexa, tech support, tech insights, tech tutorials, tech reviews, industry insights, Renukumari Senthil";
  const url = `${baseUrl}/contact`;
  const image = `${baseUrl}/images/CEO.png`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={url} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="py-12">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-8">Contact Us</h2>
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-1/2 flex justify-center mb-8 md:mb-0">
              <img
                src={`${baseUrl}/images/CEO.png`}
                alt="Renukumari Senthil"
                className="w-48 h-48 object-cover rounded-full shadow-lg"
              />
            </div>
            <div className="w-full md:w-1/2">
              <div className="mb-8">
                <h3 className="text-2xl font-semibold mb-2">
                  Renukumari Senthil
                </h3>
                <p className="text-lg mb-2">Chief Executive Officer</p>
                <p className="text-gray-700 mb-2">
                  24 C3 Extension-1, Udayar Avenue
                </p>
                <p className="text-gray-700 mb-2">Thuraiyur, TN, 621010</p>
                <p className="text-gray-700 mb-2">
                  Email: renukumari@geniusnexa.com
                </p>
                <p className="text-gray-700 mb-2">Phone: +91 9944544603</p>
              </div>
            </div>
          </div>
          <div className="mt-12">
            <h3 className="text-2xl font-semibold text-center mb-4">
              Our Location
            </h3>
            <div className="flex justify-center">
              <img
                src={`${baseUrl}/images/geniusnexa.png`}
                alt="GeniusNexa office location on Google Map"
                className="w-full md:w-2/3 lg:w-1/2 object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
