// Services.js
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet for managing the document head
import "./Services.css"; // Ensure correct path for CSS
import { baseUrl } from "../../config";
import { trackEvent } from "../../components/trackingUtils";

const Services = ({ services }) => {
  let { url } = useParams();
  const service = services.find((service) => service.url === url);

  useEffect(() => {
    trackEvent("PageLoad", "", "Services"); // Track the page load event
  }, []);

  if (!service) {
    return <div className="text-center text-gray-700">Service not found</div>;
  }

  return (
    <>
      <Helmet>
        <title>{service.title}</title>
        <meta name="description" content={service.description} />
        <meta name="keywords" content={service.keywords} />
        <meta property="og:title" content={service.title} />
        <meta property="og:description" content={service.description} />
        <meta property="og:image" content={service.imagefullurl} />
        <meta property="og:url" content={baseUrl + `/${service.url}`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div
        className="relative bg-cover bg-center h-96"
        style={{ backgroundImage: `url(${service.imagefullurl})` }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>{" "}
        {/* Overlay for text */}
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white">
          <h1 className="text-4xl font-bold mb-4">{service.title}</h1>
          <p className="text-lg">{service.description}</p>
        </div>
      </div>

      <div className="details-section container mx-auto py-12 px-4">
        {service.details &&
          service.details.map((detail, index) => (
            <div key={index} className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">{detail.title}</h2>
              <div
                className="text-gray-700"
                dangerouslySetInnerHTML={{ __html: detail.description }}
              />
            </div>
          ))}
      </div>
    </>
  );
};

export default Services;
