import React, { useEffect, useState, useCallback, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faThumbsUp,
  faThumbsDown,
  faHeart,
  faEdit,
  faTrash,
  faTimes,
  faHandsClapping,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import apiService from "../../ApiService";
import { formatDateTime } from "../../utils/DateUtils"; // Assuming you have a utility function to format time ago
import { baseUrl } from "../../config";
import UserContext from "../../contexts/UserContext";
import hljs from "highlight.js";
import "highlight.js/styles/default.css";
import GNConfetti from "../../components/GNeXConfetti";
import { toast } from "react-toastify";
import SubscribeModal from "../../components/GNSubscribeModal";
import { trackEvent } from "../../components/trackingUtils";
import SEO from "../../common/SEO";

const countWords = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  const text = div.textContent || div.innerText || "";
  return text.trim().split(/\s+/).length;
};

const calculateReadingTime = (wordCount) => {
  const wordsPerMinute = 200;
  return Math.ceil(wordCount / wordsPerMinute);
};

const BlogDetails = ({ SEOUrl, Category }) => {
  const [showConfetti, setShowConfetti] = useState(false);
  const { user, isAuthenticated } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);

  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [editingComment, setEditingComment] = useState(null);

  const fetchReactions = useCallback(async (postId) => {
    try {
      const reactionCounts = await apiService.getBlogReactionByPostId(postId);
      const reactions = {
        like: 0,
        dislike: 0,
        heart: 0,
        clap: 0,
      };
      reactionCounts.data.forEach((reaction) => {
        reactions[reaction.reaction] = reaction.count;
      });
      return reactions;
    } catch (error) {
      console.error("Error fetching reactions:", error);
      return {
        like: 0,
        dislike: 0,
        heart: 0,
        clap: 0,
      };
    }
  }, []);

  const fetchBlogPost = useCallback(async () => {
    setLoading(true);
    try {
      if (!Category || !SEOUrl) {
        console.error("Category and SEO URL parameters are required");
        setLoading(false);
        return;
      }
      const postData = await apiService.getBlogPost(Category, SEOUrl);
      const wordCount = countWords(postData.Content);
      const reactions = await fetchReactions(postData.PostID);
      postData.BlogReadTime = `${calculateReadingTime(wordCount)} min read`;
      setPost({
        ...postData,
        reactions,
      });
    } catch (error) {
      console.error("Error fetching blog post:", error);
    } finally {
      setLoading(false);
    }
  }, [Category, SEOUrl, fetchReactions]);

  useEffect(() => {
    trackEvent("PageLoad", "", "BlogDetails");
    fetchBlogPost();
  }, [fetchBlogPost]);

  useEffect(() => {
    // Apply syntax highlighting to the blog content
    if (post) {
      hljs.configure({
        languages: ["bash", "C#", "cmd", "javascript", "php"], // Adjust languages as needed
      });
      document
        .querySelectorAll(".blog-content pre.ql-syntax")
        .forEach((block) => {
          hljs.highlightElement(block);
        });
    }
  }, [post]);

  const shareUrl = baseUrl + `/blogs/${Category}/${post?.SEOUrl}`;
  const shareText = post?.Title;

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmitComment = async (event) => {
    event.preventDefault();
    if (!isAuthenticated) {
      // Handle case where user is not logged in
      console.log("User not logged in. Redirect to login or show message.");
      return;
    }

    try {
      if (editingComment) {
        await apiService.updateComment(
          editingComment.CommentID,
          user.userId,
          comment
        );
        trackEvent("Comment");
      } else {
        await apiService.postComment(post.PostID, user.userId, comment);
        trackEvent("Comment");
      }
      fetchBlogPost();
      setComment("");
      setEditingComment(null);
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const handleEditComment = (comment) => {
    setComment(comment.Content);
    setEditingComment(comment);
    trackEvent("Comment", "Edit", "CommentID:");
  };

  const handleCancelEdit = () => {
    setComment("");
    setEditingComment(null);
  };

  const handleDeleteComment = async (commentID) => {
    try {
      await apiService.deleteComment(commentID);
      trackEvent("Comment", "Delete", "Delete ID");
      fetchBlogPost();
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };
  const handleSubscribeClick = () => {
    setShowModal(true);
    trackEvent("SubscribeClick");
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleReactionClick = async (reactionType) => {
    if (!isAuthenticated) {
      toast.warning("Please log in to react to this post.");
      return;
    }

    try {
      const payload = {
        PostID: post.PostID,
        UserID: user?.userId,
        reaction: reactionType,
      };

      // Make the API call to create a blog reaction
      const response = await apiService.createBlogReaction(payload);
      const updatedReactions = await fetchReactions(post.PostID);
      setPost((prevPost) => ({
        ...prevPost,
        reactions: updatedReactions,
      }));

      // Check if the response contains the success message
      if (response.message === "Record created successfully.") {
        if (reactionType !== "dislike") {
          setShowConfetti(true);

          // Hide the confetti after a short duration
          setTimeout(() => {
            setShowConfetti(false);
          }, 3000); // Confetti duration in milliseconds
        }
      } else if (response.message === "Record updated successfully.") {
        if (reactionType !== "dislike") {
          setShowConfetti(true);

          // Hide the confetti after a short duration
          setTimeout(() => {
            setShowConfetti(false);
          }, 3000); // Confetti duration in milliseconds
        }
        trackEvent("Reaction");
      }
    } catch (error) {
      console.error(`Error ${reactionType} the post:`, error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <>
      {showConfetti && <GNConfetti />}
      {post && (
        <SEO
          title={shareText}
          description={post.MetaDescription}
          keywords={post.MetaKeywords}
          image={baseUrl + post.ImageUrl}
          url={shareUrl}
          type="article"
          facebook={
            post.FacebookImageUrl ? baseUrl + post.FacebookImageUrl : null
          }
          twitter={post.TwitterImageUrl ? baseUrl + post.TwitterImageUrl : null}
          whatsapp={
            post.WhatsappImageUrl ? baseUrl + post.WhatsappImageUrl : null
          }
          linkedin={
            post.LinkedinImageUrl ? baseUrl + post.LinkedinImageUrl : null
          }
          teams={post.TeamsImageUrl ? baseUrl + post.TeamsImageUrl : null}
          appletouchicon={
            post.AppleTouchIcon
              ? post.AppleTouchIcon.split(",")
                  .map((icon) => baseUrl + icon.trim())
                  .join(",")
              : null
          }
          windowsicon={
            post.WindowsIcon
              ? post.WindowsIcon.split(",")
                  .map((icon) => baseUrl + icon.trim())
                  .join(",")
              : null
          }
          androidicons={
            post.AndroidIcon
              ? post.AndroidIcon.split(",")
                  .map((icon) => baseUrl + icon.trim())
                  .join(",")
              : null
          }
        />
      )}
      <div className="blog-page">
        <div className="container mx-auto py-12 px-4">
          {loading && (
            <div className="text-center">
              <div
                className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
              <p className="loading-message">Loading blog post...</p>
            </div>
          )}
          {post && (
            <div className="blog-post">
              <h1 className="text-3xl font-bold mb-4">{post.Title}</h1>
              <div className="author-header mb-2 flex flex-col sm:flex-row justify-between items-start sm:items-center">
                <div className="author-info flex items-center mb-2 sm:mb-0">
                  <div>
                    <p className="author-name margin-0">{post.Author}</p>
                    <p className="published-date margin-0">
                      published {formatDateTime(post.ModifiedDate)}
                    </p>
                  </div>
                </div>
                <div className="subscribe mb-2 sm:mb-0">
                  <button
                    className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
                    onClick={() => handleSubscribeClick()}
                  >
                    Subscribe
                  </button>
                </div>
                <div className="action-icons flex space-x-4">
                  <div className="icon-container flex items-center">
                    <FontAwesomeIcon
                      icon={faThumbsUp}
                      aria-hidden="true"
                      title="Like"
                      className="icon like-icon cursor-pointer"
                      onClick={() => handleReactionClick("like")}
                    />
                    <span className="count ml-2">{post.reactions?.like}</span>
                  </div>
                  <div className="icon-container flex items-center">
                    <FontAwesomeIcon
                      icon={faThumbsDown}
                      aria-hidden="true"
                      title="Dislike"
                      className="icon dislike-icon cursor-pointer"
                      onClick={() => handleReactionClick("dislike")}
                    />
                    <span className="count ml-2">
                      {post.reactions?.dislike}
                    </span>
                  </div>
                  <div className="icon-container flex items-center">
                    <FontAwesomeIcon
                      icon={faHeart}
                      aria-hidden="true"
                      title="Heart"
                      className="icon heart-icon cursor-pointer"
                      onClick={() => handleReactionClick("heart")}
                    />
                    <span className="count ml-2">{post.reactions?.heart}</span>
                  </div>
                  <div className="icon-container flex items-center">
                    <FontAwesomeIcon
                      icon={faHandsClapping}
                      aria-hidden="true"
                      title="Clap"
                      className="icon clap-icon cursor-pointer"
                      onClick={() => handleReactionClick("clap")}
                    />
                    <span className="count ml-2">{post.reactions?.clap}</span>
                  </div>
                </div>
              </div>

              <div className="blog-header mb-4">
                <img
                  src={baseUrl + post.ImageUrl}
                  alt={post.Title}
                  className="w-full h-1/2 object-cover rounded-lg"
                />
                {post.PhotoCredit && (
                  <div className="text-sm text-gray-500 mt-2">
                    {post.PhotoCredit}
                  </div>
                )}
                <div className="reading-time mt-2 flex items-center text-gray-500">
                  <FontAwesomeIcon
                    icon={faClock}
                    aria-hidden="true"
                    title="read"
                    className="icon read-icon mr-2"
                  />
                  {post.BlogReadTime}
                </div>
              </div>

              <div
                className="blog-content mt-3 space-y-4"
                dangerouslySetInnerHTML={{ __html: post.Content }}
              />
              <div className="blog-footer mt-8">
                <div className="footer-icons mb-4">
                  <span className="text-gray-500">
                    <FontAwesomeIcon icon={faComment} /> {post.Comments.length}
                  </span>
                </div>
                <div className="social-share">
                  <h3 className="text-lg font-semibold mb-2">
                    Share this post:
                  </h3>
                  <div className="flex space-x-4">
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800 transition duration-300"
                    >
                      <FontAwesomeIcon icon={faFacebook} size="2x" />
                    </a>
                    <a
                      href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareText}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-400 hover:text-blue-600 transition duration-300"
                    >
                      <FontAwesomeIcon icon={faTwitter} size="2x" />
                    </a>
                    <a
                      href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${shareText}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-700 hover:text-blue-900 transition duration-300"
                    >
                      <FontAwesomeIcon icon={faLinkedin} size="2x" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="comments-section mt-8">
                <h3 className="text-2xl font-bold mb-4">Comments</h3>
                {post.Comments.map((comment) => (
                  <div
                    key={comment.CommentID}
                    className="comment mb-4 p-4 bg-gray-100 rounded-lg"
                  >
                    <div className="comment-content">
                      <div className="comment-user font-semibold">
                        {comment.UserName}
                      </div>
                      <p className="text-gray-700">{comment.Content}</p>
                      <p className="text-sm text-gray-500">
                        {formatDateTime(comment.CreatedDate)}
                      </p>
                    </div>
                    {isAuthenticated && user.userId === comment.UserID && (
                      <div className="comment-actions mt-2 flex space-x-2">
                        <button
                          onClick={() => handleEditComment(comment)}
                          className="text-blue-500 hover:text-blue-700 transition duration-300"
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                        <button
                          onClick={() => handleDeleteComment(comment.CommentID)}
                          className="text-red-500 hover:text-red-700 transition duration-300"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {isAuthenticated && (
                <div className="comment-form mt-8">
                  <form onSubmit={handleSubmitComment}>
                    <div className="mb-4">
                      <label className="block text-gray-700 font-semibold mb-2">
                        Your Comment:
                      </label>
                      <textarea
                        className="w-full p-2 border border-gray-300 rounded-lg"
                        rows={3}
                        value={comment}
                        onChange={handleCommentChange}
                      />
                    </div>
                    <div className="form-buttons flex space-x-2">
                      <button
                        type="submit"
                        className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
                      >
                        {editingComment ? "Update Comment" : "Post Comment"}
                      </button>
                      {editingComment && (
                        <button
                          type="button"
                          onClick={handleCancelEdit}
                          className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600 transition duration-300"
                        >
                          <FontAwesomeIcon icon={faTimes} /> Cancel
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              )}
            </div>
          )}
          {!loading && !post && (
            <p className="not-found-message text-center text-gray-700">
              Blog post not found.
            </p>
          )}
        </div>
      </div>
      <SubscribeModal
        useremail={user?.userEmail}
        show={showModal}
        handleClose={handleCloseModal}
      />
    </>
  );
};

export default BlogDetails;
