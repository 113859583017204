import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import PostForm from "./PostForm";
import apiService from "../../ApiService";
import { formatDateTime } from "../../utils/DateUtils";
import PostView from "./PostView";

const ReviewBlogs = ({ user }) => {
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [posts, setPosts] = useState([]);
  const [showEditor, setShowEditor] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [editPost, setEditPost] = useState({
    PostID: null,
    BlogID: null,
    Title: "",
    Content: "",
    SEOUrl: "",
    MetaDescription: "",
    MetaKeywords: "",
    ImageUrl: null,
    TwitterImageUrl: null,
    FacebookImageUrl: null,
    WhatsappImageUrl: null,
    LinkedinImageUrl: null,
    Status: 0,
  });

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [, setSelectedBlogTitle] = useState("Filter Items");
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [postToApprove, setPostToApprove] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loadingPage, setLoadingPage] = useState(false); // State for loading page change

  // Preview state
  const [showPreview, setShowPreview] = useState(false);
  const [previewPost, setPreviewPost] = useState(null);

  const fetchBlogPostsByCategory = useCallback(
    async (category, status, page, pagesize, searchTitle) => {
      try {
        const pageSizeToUse = pagesize === undefined ? itemsPerPage : pagesize;
        setLoadingPage(true); // Start loading page change
        const response = await apiService.fetchBlogPostByCategory(
          category,
          page,
          pageSizeToUse,
          searchTitle,
          status
        );
        if (response.length === 0) {
          setPosts([]);
          setError("No posts found.");
        } else {
          setPosts(response.posts);
          setTotalPages(response.totalPages); // Assuming the API returns the total number of pages
          setError(null);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setPosts([]);
          setError("No items found.");
        } else {
          setError(`${error.message}`);
          setPosts([]);
        }
      } finally {
        setLoadingPage(false); // Stop loading page change
      }
    },
    [itemsPerPage]
  );

  useEffect(() => {
    const loadBlogs = async () => {
      try {
        const data = await apiService.fetchBlog();
        setBlogs(data);
        if (data && data.length > 0) {
          const firstBlogId = data[0].BlogID;
          setSelectedBlog(firstBlogId);
          setSelectedBlogTitle(data[0].Title);
          fetchBlogPostsByCategory(data[0].SEOUrl, 2, 1);
        }
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    loadBlogs();
  }, [fetchBlogPostsByCategory]);

  const handleEditPost = (post) => {
    setEditPost(post);
    setShowEditor(true);
  };

  const handleApprovePost = async (PostID) => {
    try {
      setPostToApprove(PostID);
      setShowApproveModal(true);
    } catch (error) {
      setError(`Error deleting post: ${error.message}`);
    }
  };

  const confirmApprove = async () => {
    try {
      await apiService.approveRejectArticle(postToApprove, 1);
      const blogfind = blogs.find((blog) => blog.BlogID === selectedBlog);
      await fetchBlogPostsByCategory(blogfind.SEOUrl, 2, currentPage);
    } catch (error) {
      setError(`Error deleting post: ${error.message}`);
    } finally {
      setShowApproveModal(false);
    }
  };
  const handleCancelModal = () => {
    setPostToApprove(null);
    setShowApproveModal(false);
  };
  const handlePreview = (post) => {
    setPreviewPost(post);
    setShowPreview(true);
    setShowEditor(false);
  };

  const confirmReject = async () => {
    try {
      await apiService.approveRejectArticle(postToApprove, 3);
      const blogfind = blogs.find((blog) => blog.BlogID === selectedBlog);
      await fetchBlogPostsByCategory(blogfind.SEOUrl, 2, currentPage);
    } catch (error) {
      setError(`Error deleting post: ${error.message}`);
    } finally {
      setShowApproveModal(false);
    }
  };

  const handleSelectItemsPerPage = async (eventKey) => {
    const svalue = parseInt(eventKey, 10);
    setItemsPerPage(svalue);
    try {
      const blogfind = blogs.find((blog) => blog.BlogID === selectedBlog);
      await fetchBlogPostsByCategory(blogfind.SEOUrl, 2, currentPage, svalue); // Fetch with current page
    } catch (error) {
      setError(`Error reloading posts: ${error.message}`);
    }
  };

  const handleSavePost = async (formData) => {
    setShowEditor(false);
    setEditPost({
      PostID: null,
      Title: "",
      Content: "",
      SEOUrl: "",
      MetaDescription: "",
      MetaKeywords: "",
      ImageUrl: null,
      TwitterImageUrl: null,
      FacebookImageUrl: null,
      WhatsappImageUrl: null,
      LinkedinImageUrl: null,
      BlogID: null,
      Status: 0,
    });
    try {
      const blogfind = blogs.find((blog) => blog.BlogID === selectedBlog);
      await fetchBlogPostsByCategory(blogfind.SEOUrl, 2, currentPage);
    } catch (error) {
      setError(`Error reloading posts: ${error.message}`);
    }
  };

  const handleSelectBlog = async (blogId, event) => {
    blogId = parseInt(blogId); // Convert blogId to an integer
    setSelectedBlog(blogId);
    const selectedBlog = blogs.find((blog) => blog.BlogID === blogId);
    setSelectedBlogTitle(selectedBlog ? selectedBlog.Title : "Filter Items");

    try {
      await fetchBlogPostsByCategory(selectedBlog.SEOUrl, 2, 1); // Reset to page 1
      setCurrentPage(1);
    } catch (error) {
      // Handle error, if needed
    }
  };
  const handleSearchInputChange = (event) => {
    setSearchTitle(event.target.value); // Update searchTitle state
    // Call API with searchTitle
    const blogfind = blogs.find((blog) => blog.BlogID === selectedBlog);
    fetchBlogPostsByCategory(
      blogfind.SEOUrl,
      2,
      currentPage,
      itemsPerPage,
      event.target.value
    );
  };

  const handlePageChange = async (page) => {
    setCurrentPage(page);
    try {
      setLoadingPage(true); // Start loading page change
      const blogfind = blogs.find((blog) => blog.BlogID === selectedBlog);
      await fetchBlogPostsByCategory(blogfind.SEOUrl, 2, page);
    } catch (error) {
      setError(`Error fetching blog posts: ${error.message}`);
    } finally {
      setLoadingPage(false); // Stop loading page change
    }
  };

  const renderPaginationItems = () => {
    const items = [];
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, currentPage + 1);

    if (currentPage > 1) {
      items.push(
        <button
          key="first"
          onClick={() => handlePageChange(1)}
          className="px-3 py-1 border rounded-l-lg"
        >
          First
        </button>
      );
      items.push(
        <button
          key="prev"
          onClick={() => handlePageChange(currentPage - 1)}
          className="px-3 py-1 border"
        >
          Prev
        </button>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <button
          key={i}
          className={`px-3 py-1 border ${
            i === currentPage ? "bg-blue-500 text-white" : ""
          }`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    if (currentPage < totalPages) {
      items.push(
        <button
          key="next"
          onClick={() => handlePageChange(currentPage + 1)}
          className="px-3 py-1 border"
        >
          Next
        </button>
      );
      items.push(
        <button
          key="last"
          onClick={() => handlePageChange(totalPages)}
          className="px-3 py-1 border rounded-r-lg"
        >
          Last
        </button>
      );
    }

    return items;
  };

  return (
    <>
      {!showEditor && !showPreview && (
        <div className="container mx-auto py-12 px-4">
          <div className="bg-white p-8 ">
            <div className="flex flex-wrap justify-between items-center mb-4">
              <div className="w-full md:w-1/2 flex flex-col md:flex-row items-center mb-4 md:mb-0">
                <input
                  value={searchTitle}
                  type="text"
                  placeholder="Search..."
                  className="w-full md:w-auto p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4 md:mb-0 md:mr-4"
                  onChange={handleSearchInputChange}
                />
                <div className="relative w-full md:w-auto">
                  <select
                    className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onChange={(e) => handleSelectBlog(e.target.value)}
                    value={selectedBlog}
                  >
                    <option value="" disabled>
                      {loading ? "Loading..." : "Filter Items"}
                    </option>
                    {blogs.map((blog) => (
                      <option
                        key={blog.BlogID}
                        value={blog.BlogID}
                        className="py-4"
                      >
                        {blog.Title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="gn-body">
              {loadingPage ? (
                <div className="text-center mt-4">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {posts && posts.length > 0 ? (
                    posts.map((post) => (
                      <div
                        key={post.PostID}
                        className={`bg-white p-4 rounded-lg shadow-lg ${
                          post.Status === 0 ? "border border-red-500" : ""
                        }`}
                      >
                        <h3 className="text-xl font-semibold mb-2">
                          {post.Title}
                        </h3>
                        <div className="blog-post-content-admin mb-2">
                          <div
                            dangerouslySetInnerHTML={{ __html: post.Content }}
                          />
                        </div>
                        <div className="post-meta mb-2">
                          <p className="author text-gray-700">{post.Author}</p>
                          <p className="published-date text-gray-500">
                            {formatDateTime(post.ModifiedDate)}
                          </p>
                        </div>
                        <div className="flex space-x-2">
                          <button
                            onClick={() => handleEditPost(post)}
                            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                          >
                            <FontAwesomeIcon icon={faEdit} className="mr-2" />
                            Edit
                          </button>
                          <button
                            onClick={() => handlePreview(post)}
                            className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition duration-300"
                          >
                            <FontAwesomeIcon icon={faEye} className="mr-2" />
                            Preview
                          </button>
                          <button
                            onClick={() => handleApprovePost(post.PostID)}
                            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300"
                          >
                            <FontAwesomeIcon icon={faCheck} className="mr-2" />
                            Approve
                          </button>
                        </div>
                      </div>
                    ))
                  ) : error ? (
                    <div className="col-span-1 md:col-span-2 lg:col-span-3">
                      <p className="text-red-500">{error}</p>
                    </div>
                  ) : (
                    <div className="col-span-1 md:col-span-2 lg:col-span-3">
                      <p>No posts available.</p>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="flex justify-between items-center mt-8">
              <div>
                <select
                  value={itemsPerPage}
                  onChange={(e) => handleSelectItemsPerPage(e.target.value)}
                  className="p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  {[6, 9, 12].map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex space-x-2">{renderPaginationItems()}</div>
            </div>
          </div>
        </div>
      )}
      {showEditor && (
        <div className="container mx-auto py-12 px-4">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">Edit Post</h2>
              <button
                onClick={() => setShowEditor(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition duration-300"
              >
                Close
              </button>
            </div>
            <PostForm
              post={editPost}
              onSave={handleSavePost}
              onCancel={() => setShowEditor(false)}
              BlogID={selectedBlog}
              UserID={user?.userId}
              Status={2}
              blogsData={blogs}
            />
          </div>
        </div>
      )}
      {showPreview && (
        <PostView post={previewPost} onBack={() => setShowPreview(false)} />
      )}
      {showApproveModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-white p-6 rounded-lg shadow-lg z-10">
            <h2 className="text-xl font-bold mb-4">Confirm Approve/Reject</h2>
            <p className="mb-4">Please Approve or Reject Post?</p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={handleCancelModal}
                className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition duration-300"
              >
                Cancel
              </button>
              <button
                onClick={confirmApprove}
                className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300"
              >
                Approve
              </button>
              <button
                onClick={confirmReject}
                className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-300"
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReviewBlogs;
