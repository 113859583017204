let apiUrl = "https://www.geniusnexa.com/api";
let baseUrl = "https://www.geniusnexa.com";
let restApiUrl = "https://www.geniusnexa.com/api";
let basicAuth = "Basic " + btoa("geniusnexa:geniusnexa");

if (process.env.NODE_ENV === "development") {
  apiUrl = "https://www.geniusnexa.com/api";
  restApiUrl = "https://www.geniusnexa.com/api";
  baseUrl = "https://www.geniusnexa.com";
} else if (process.env.NODE_ENV === "production") {
  apiUrl = "https://www.geniusnexa.com/api";
  restApiUrl = "https://www.geniusnexa.com/api";
  baseUrl = "https://www.geniusnexa.com";
}

module.exports = { apiUrl, restApiUrl, basicAuth, baseUrl };
