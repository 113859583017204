import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
import apiService from "../../ApiService";
import { baseUrl } from "../../config";
import { trackEvent } from "../../components/trackingUtils";

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    trackEvent("PageLoad", "", "Blog");
    const fetchBlogPosts = async () => {
      setLoading(true);
      try {
        const data = await apiService.fetchBlog();
        setBlogPosts(data);
        setError(null);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
        setError("Error fetching blog posts. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPosts();
  }, []);

  // Generate meta tags based on the blog posts
  const generateMetaTags = () => {
    if (blogPosts.length === 0) {
      return (
        <SEO
          title="GeniusNexa Blog - Latest Tech Insights"
          description={`Discover the latest tech insights, tutorials, and reviews on the GeniusNexa blog.`}
          keywords="Artificial intelligence advancements, Future of AI, AI trends, Healthy habits, Balanced lifestyle tips, Nutrition tips, Fitness routines, Personal finance tips, Finance management strategies, Budgeting tips, Investing strategies, Sustainable living guide, Eco-friendly practices, Green living tips, Trending news, Latest news updates, Microsoft Azure expert guide, Azure cloud services, Azure tips and tricks, Cloud application development"
          url={baseUrl + "/blogs"}
        />
      );
    }

    const firstPost = blogPosts[0];
    return (
      <SEO
        title="GeniusNexa Blog - Latest Tech Insights, Trends, and Innovations"
        description={`Discover the latest tech insights, tutorials, and reviews on the GeniusNexa blog. Featured post: ${firstPost.Title}`}
        keywords="GeniusNexa blog, tech articles, technology trends, tech tutorials, tech reviews, industry insights, best practices, developer blog, tech updates, innovation"
        image={baseUrl + "/images/blogs.png"}
        url={baseUrl + "/blogs"}
      />
    );
  };

  return (
    <div className="container mx-auto py-12 px-4">
      {generateMetaTags()} {/* Add SEO tags here */}
      {loading && (
        <div className="text-center mt-3">
          <div
            className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {error && (
        <div className="alert alert-danger text-center" role="alert">
          {error}
        </div>
      )}
      {!loading && (
        <>
          <h1 className="text-3xl font-bold mb-8">Blogs</h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {blogPosts && blogPosts.length > 0 ? (
              blogPosts.map((post, idx) => (
                <Link
                  key={idx}
                  to={`/blogs/${post.SEOUrl}`}
                  className="block bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
                >
                  <img
                    src={
                      post.ImageUrl
                        ? baseUrl + "/" + post.ImageUrl
                        : `https://via.placeholder.com/150x150?text=${idx}`
                    }
                    alt={post.Title}
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-4">
                    <h3 className="text-lg font-semibold mb-2">{post.Title}</h3>
                    <p className="text-gray-700">{post.Description}</p>
                  </div>
                </Link>
              ))
            ) : (
              <div className="w-full text-center text-gray-700">
                No blog posts found.
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Blog;
