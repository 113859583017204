import React, { useState } from "react";
import { diffWordsWithSpace, diffJson } from "diff";
import "prismjs/themes/prism-tomorrow.css"; // Import Prism theme for syntax highlighting
import beautify from "json-beautify";
import ReactJson from "react-json-view"; // Import ReactJson for tree view
import "./TextComparison.css"; // Import custom CSS
import { Helmet } from "react-helmet"; // Import Helmet for managing the document head
import { baseUrl } from "../config";

const TextComparison = () => {
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [diffHtml, setDiffHtml] = useState("");
  const [formattedJson1, setFormattedJson1] = useState(null);
  const [formattedJson2, setFormattedJson2] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isJsonComparison, setIsJsonComparison] = useState(false);

  const formatJson = (jsonString) => {
    try {
      const jsonObject = JSON.parse(jsonString);
      return beautify(jsonObject, null, 2, 80);
    } catch (e) {
      return null;
    }
  };

  const compareTexts = () => {
    setLoading(true);
    setError(""); // Ensure error state is cleared before starting the comparison
    setDiffHtml("");
    setFormattedJson1(null);
    setFormattedJson2(null);

    try {
      if (isJsonComparison) {
        // Format JSON
        const formatted1 = formatJson(text1);
        const formatted2 = formatJson(text2);

        if (formatted1 === null || formatted2 === null) {
          setError("Invalid JSON format. Please check your input.");
          setLoading(false);
          return;
        }

        // Parse JSON for comparison
        const obj1 = JSON.parse(formatted1);
        const obj2 = JSON.parse(formatted2);

        // Compare JSON
        const diff = diffJson(obj1, obj2);

        // Generate HTML for JSON differences
        const html = diff
          .map((part) => {
            if (part.added)
              return `<span class="json-added">${part.value}</span>`;
            if (part.removed)
              return `<span class="json-removed">${part.value}</span>`;
            return part.value;
          })
          .join("");

        setDiffHtml(html);

        // Set formatted JSON results
        setFormattedJson1(obj1);
        setFormattedJson2(obj2);
      } else {
        // Compare plain text
        const diff = diffWordsWithSpace(text1, text2);

        // Generate HTML for text differences
        const html = diff
          .map((part) => {
            if (part.added) return `<span class="added">${part.value}</span>`;
            if (part.removed)
              return `<span class="removed">${part.value}</span>`;
            return part.value;
          })
          .join("");

        setDiffHtml(html);
      }
    } catch (err) {
      setError("An error occurred while comparing texts.");
    } finally {
      setLoading(false);
    }
  };

  const clearText = () => {
    setText1("");
    setText2("");
    setDiffHtml("");
    setFormattedJson1(null);
    setFormattedJson2(null);
    setError("");
  };

  const handleFileUpload = (event, setText) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setText(e.target.result);
      };
      reader.readAsText(file);
    }
  };

  const title =
    "CompareXpert - Ultimate Tool for Text, JSON, PDF, and Image Comparisons";
  const description =
    "Unlock the power of CompareXpert, your all-in-one tool for comparing text, JSON, PDFs, and images. Effortlessly identify differences, optimize content, and streamline your comparison tasks. Start using CompareXpert today for precise and efficient comparisons.";
  const keywords =
    "CompareXpert, text comparison tool, JSON comparison, PDF comparison, image comparison, comparison tool, diff analyzer, content optimization";

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={`${baseUrl}/images/comparexpert.png`}
        />
        <meta property="og:url" content={`${baseUrl}/tools/comparexpert`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className="container mx-auto py-12 px-4">
        <div className="mb-4 flex justify-center">
          <button
            className={`px-4 py-2 rounded-l-lg ${
              !isJsonComparison ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
            onClick={() => setIsJsonComparison(false)}
          >
            Compare as Text
          </button>
          <button
            className={`px-4 py-2 rounded-r-lg ${
              isJsonComparison ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
            onClick={() => setIsJsonComparison(true)}
          >
            Compare as JSON
          </button>
          <button
            className="ml-4 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-300"
            onClick={clearText}
          >
            Clear
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-2">Original text</h3>
            <textarea
              rows={10}
              value={text1}
              onChange={(e) => setText1(e.target.value)}
              placeholder="Enter the first text here..."
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="file"
              accept=".txt,.json"
              onChange={(e) => handleFileUpload(e, setText1)}
              className="mt-2"
            />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-2">Changed text</h3>
            <textarea
              rows={10}
              value={text2}
              onChange={(e) => setText2(e.target.value)}
              placeholder="Enter the second text here..."
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="file"
              accept=".txt,.json"
              onChange={(e) => handleFileUpload(e, setText2)}
              className="mt-2"
            />
          </div>
        </div>

        <button
          className="mt-4 w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-300"
          onClick={compareTexts}
          disabled={loading || !text1 || !text2}
        >
          {loading ? "Comparing..." : "Compare"}
        </button>

        {error && !loading && !isJsonComparison && (
          <div className="mt-4 text-red-500 text-center">{error}</div>
        )}

        {(diffHtml || formattedJson1 || formattedJson2) && (
          <div className="mt-4 bg-white p-4 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-2">Comparison Result</h3>
            {isJsonComparison ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <h4 className="text-md font-semibold mb-2">Original JSON</h4>
                  <ReactJson src={formattedJson1} theme="monokai" />
                </div>
                <div>
                  <h4 className="text-md font-semibold mb-2">Changed JSON</h4>
                  <ReactJson src={formattedJson2} theme="monokai" />
                </div>
              </div>
            ) : (
              <div
                className="diff-result"
                dangerouslySetInnerHTML={{ __html: diffHtml }}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default TextComparison;
