import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ImageUpload from "./ImageUpload";
import SocialMediaFormats from "./SocialMediaFormats";
import { baseUrl } from "../config";
import { trackEvent } from "../components/trackingUtils";
import "./ImageConverter.css";

const ImageConverter = () => {
  const [socialMediaPaths, setSocialMediaPaths] = useState({
    twitter: null,
    facebook: null,
    whatsapp: null,
    linkedin: null,
  });
  const [uploadError, setUploadError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    trackEvent("PageLoad", "", "ImageConverter");
  }, []);

  const handleImageUpload = (imageUrl, paths, error) => {
    trackEvent("Click", paths);
    if (error) {
      setUploadError(true);
    } else {
      setSocialMediaPaths(paths);
      setUploadError(false);
    }
    setLoading(false);
  };

  const handleUploadStart = () => {
    setLoading(true);
  };

  const title =
    "Free Online Image Converter | Convert Images to Social Media Formats";
  const description =
    "Easily convert your images into various social media formats with our free online image converter tool. Upload your image, customize formats for platforms like Twitter, Facebook, LinkedIn, and WhatsApp, and download your optimized images quickly and effortlessly. Try it now and enhance your social media presence!";
  const keywords =
    "Image Converter,Online Image Converter,Convert Images to Social Media Formats,Image Upload and Conversion Tool,Free Image Format Converter,Social Media Image Resizer,Image Converter Tool Online";

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={`${baseUrl}/images/ImageConverter.png`}
        />
        <meta property="og:url" content={`${baseUrl}/tools/imageconverter`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="container mx-auto py-12 px-4">
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <ImageUpload
            onImageUpload={(imageUrl, paths, error) =>
              handleImageUpload(imageUrl, paths, error)
            }
            onUploadStart={handleUploadStart}
          />
          {loading && (
            <div className="flex justify-center items-center mt-4">
              <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-blue-500"></div>
            </div>
          )}
          {!uploadError &&
            Object.values(socialMediaPaths).some((path) => path !== null) && (
              <SocialMediaFormats socialMediaPaths={socialMediaPaths} />
            )}
        </div>
      </div>
    </>
  );
};

export default ImageConverter;
