import React from "react";

const AdminSettings = () => {
  return (
    <div className="container mx-auto py-12 px-4">
      <h1 className="text-3xl font-bold text-center mb-8">Admin Dashboard</h1>
      <p className="text-center mb-8">Welcome to the admin dashboard.</p>
    </div>
  );
};

export default AdminSettings;
