import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloud,
  faCode,
  faDatabase,
  faLaptopCode,
  faFilePdf,
  faCogs,
  faUser,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import { baseUrl } from "../../config";
import { trackEvent } from "../../components/trackingUtils";

const ServicesMaster = ({ services }) => {
  useEffect(() => {
    trackEvent("PageLoad", "", "ServicesMaster"); // Track the page load event
  }, []);

  const getServiceIcon = (icons) => {
    switch (icons) {
      case "Development":
        return faCode;
      case "Cloud":
        return faCloud;
      case "Consulting":
        return faUser;
      case "website building":
        return faLaptopCode;
      case "PDF":
        return faFilePdf;
      case "Data":
        return faDatabase;
      case "Analytics":
        return faChartLine;
      default:
        return faCogs;
    }
  };

  const title = "GeniusNexa - Our Services";
  const description =
    "Explore the range of services offered by GeniusNexa. From technology solutions to consulting, discover how we can help you achieve your goals.";
  const keywords =
    "Cloud services, Cloud infrastructure enhancement, .NET development, Python development, PDF conversion, PDF document management, Data scraping services, Data extraction, Data management, Data organization, Website building, Responsive website design, Custom website development, Web development services";

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={`${baseUrl}/images/ourservices.png`}
        />
        <meta property="og:url" content={`${baseUrl}/services`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="services-list container mx-auto py-12 px-4">
        <h1 className="text-4xl font-bold text-center mb-8">Our Services</h1>
        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <li
              key={index}
              className="bg-white shadow-md rounded-lg overflow-hidden transform transition duration-300 hover:scale-105"
            >
              <Link
                to={`/services/${service.url}`}
                className="block p-6 hover:bg-gray-100 transition duration-300"
              >
                <div className="flex items-center mb-4">
                  <FontAwesomeIcon
                    icon={getServiceIcon(service.icon)}
                    className="text-blue-500 text-3xl mr-4"
                  />
                  <h3 className="text-2xl font-semibold">{service.title}</h3>
                </div>
                <p className="text-gray-700">{service.description}</p>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default ServicesMaster;
